import { useLocation } from 'react-router-dom';

import { CourseDescription } from '../components/CourseDescription';
import { CourseHeader } from '../components/CourseHeader';
import { CourseReviews } from '../components/CourseReviews';
import { StudentFeedback } from '../components/StudentFeedback';

import { sampleCourses } from './Academy';
import { PurchasedCourseDetails } from './PurchasedCourseDetails';

export const CourseDetails = () => {
  const { search } = useLocation();
  const id: number = parseInt(search.split('id=')[1]);
  const { purchased } = sampleCourses[id];

  return (
    <div>
      {purchased ? (
        <PurchasedCourseDetails />
      ) : (
        <>
          <CourseHeader />
          <div className='px-8 mt-8 sm:mt-0 mb-8 sm:my-0'>
            <CourseDescription />
          </div>

          <div className='px-8 mt-8 sm:mt-4 mb-8 sm:my-0'>
            <StudentFeedback />
          </div>

          <div className='px-8 mt-8 sm:mt-0 mb-8 sm:mb-16'>
            <CourseReviews />
          </div>
        </>
      )}
    </div>
  );
};
