/* eslint-disable no-restricted-imports */
import { useEffect } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';

import { Landing } from '@/features/landing';
import storage from '@/utils/storage';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const token = storage.getToken();
  const loggedInUser = storage.getUser();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      (token && location.pathname.includes('auth/sign')) ||
      (location.pathname.includes('portal') && !token)
    ) {
      storage.clearToken();
      navigate('/auth/signin');
    }
  }, [token, navigate]);
  const commonRoutes = [
    {
      path: '/',
      element: <Landing />,
    },
  ];
  const routes = token
    ? loggedInUser.userType === 'ADMIN'
      ? [protectedRoutes[0], protectedRoutes[1], protectedRoutes[3]]
      : [protectedRoutes[0], protectedRoutes[1], protectedRoutes[2]]
    : publicRoutes;
  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
