export const getFooterLinks = (isLoggedIn: boolean) => {
  return [
    {
      title: 'Company',
      children: [
        {
          title: 'Our Business & Services',
          link: '/#services',
        },
        {
          title: 'Awap Digital Academy',
          link: isLoggedIn ? '/app/academy' : '/academy',
        },
        {
          title: 'Buy & Sell Crypto',
          link: isLoggedIn ? '/app/buycrypto' : '/buycrypto',
        },
        {
          title: 'Business Mentorship',
          link: isLoggedIn ? '/app/mentorship' : '/mentorship',
        },
      ],
    },
    {
      title: 'Quicklinks',
      children: [
        {
          title: 'Terms of Use',
          link: isLoggedIn ? '/app/terms' : '/terms',
        },
        {
          title: 'About Us',
          link: isLoggedIn ? '/app/about' : '/about',
        },
        {
          title: 'Privacy Policy',
          link: isLoggedIn ? '/app/privacy-policy' : '/privacy-policy',
        },
        {
          title: 'Certification',
          link: isLoggedIn ? '/app/certification' : '/certification',
        },
        {
          title: 'Blog',
          link: isLoggedIn ? '/app/blog' : '/blog',
        },
      ],
    },
  ];
};

export const contactDetails = {
  title: 'Contact Us',
  details: [
    {
      detail: 'Tel',
      value: '+2348064459085 +234907393069',
    },
    {
      detail: 'Email',
      value: 'info@awapinvestors.com',
    },
    {
      detail: 'Address',
      value: '150 Afikpo Road, Abakaliki, Ebonyi State, Nigeria.',
    },
  ],
};
