import { Link } from 'react-router-dom';

import { filledstar, clock } from '@/assets';
import storage from '@/utils/storage';

import { CourseCategoryProps, CourseProps } from '../types';

const Card = ({ course }: { course: CourseProps }) => {
  const token = storage.getToken();

  const { purchased, progress } = course;
  return (
    <div className='shadow-all-sides flex flex-col rounded-md'>
      <div className='flex flex-col flex-[0.6] p-1 border-b-[1px]'>
        <img alt='course thumbnail' src={course.thumbnail} />
        <h3 className='my-1 py-2 text-indigo text-md'>{course.title}</h3>
      </div>

      <div className='flex flex-col justify-between flex-[0.4] p-1'>
        <div className='flex justify-between'>
          <div className='flex items-center'>
            <img className='h-4 mr-2' src={filledstar} alt='filledstar icon' />
            <span className='text-indigo text-sm'>{course.ratings}</span>
          </div>
          <div className='flex items-center'>
            <img className='h-4 mr-2' src={clock} alt='clock icon' />
            <span className='text-indigo text-sm'>{course.duration}</span>
          </div>
        </div>

        <div className='flex justify-between mt-4'>
          <div>
            {course.purchased && token ? (
              <span
                className={`${
                  course.progress === 100 ? 'text-[#00D467]' : ''
                } mr-1 text-indigo text-sm`}
              >
                {course.progress}% Completion
              </span>
            ) : (
              <span className='mr-1 text-indigo text-sm'>
                $
                {course.discounted_price
                  ? course.discounted_price
                  : course.original_price}
              </span>
            )}

            {!course.purchased && course.discounted_price && (
              <span className='text-[#A6A6A6] text-decoration-line: line-through text-sm'>
                ${course.original_price}
              </span>
            )}
          </div>

          <div className='flex justify-center'>
            {token && purchased ? (
              progress === 0 || progress === 100 ? (
                <button className='flex items-center justify-center bg-indigo hover:bg-yellow text-[12px] h-[30px] w-[60px] p-2 rounded-md text-white'>
                  {progress === 0 ? 'Start' : 'View'}
                </button>
              ) : (
                <button className='flex items-center justify-center bg-yellow hover:bg-indigo text-[12px] h-[30px] w-[60px] p-2 rounded-md text-indigo hover:text-white'>
                  Continue
                </button>
              )
            ) : (
              <button className='flex items-center justify-center bg-indigo hover:bg-yellow text-[12px] h-[30px] w-[60px] p-2 rounded-md text-white'>
                Buy
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Courses = ({
  data,
  type,
}: {
  data: CourseProps[];
  type?: CourseCategoryProps;
}) => {
  return (
    <div className=''>
      <div className='grid justify-between grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-template-columns: 1fr 1fr 1fr gap-4'>
        {data.map((course, index) => (
          <Link
            key={course.title}
            to={`${
              type === 'my_courses' || type === 'discover' ? '/app' : ''
            }/academy/courses?id=${index}`}
          >
            <Card key={course.title} course={course} />
          </Link>
        ))}
      </div>

      <div className='flex justify-center mt-8'>
        <button className='bg-transparent border-[#52516B] border-[1px] hover:bg-indigo hover:text-white text-[12px] h-[40px] w-[200px] p-2 rounded-md text-indigo text-tranform: uppercase'>
          Load More
        </button>
      </div>
    </div>
  );
};
