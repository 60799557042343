import React, { useMemo } from 'react';

import { Spinner } from '@/components/Elements';
import { TableMenu } from '@/components/Elements/Menu';
import { Head } from '@/components/Head';
import { Table } from '@/components/Table';
import { formatDate } from '@/utils/functions';

import { useGetTransactions } from '../hooks';

export const Transactions = () => {
  const { data: transactions, isLoading } = useGetTransactions();

  const handleEdit = (_id: string) => {
    console.log(_id);
  };

  const handleView = (_id: string) => {
    console.log(_id);
  };

  const handleDelete = (_id: string) => {
    console.log(_id);
  };

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'menu',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div className=''>
            <TableMenu
              _id={row.original._id}
              handleEdit={() => handleEdit(row.original)}
              handleDelete={() => handleDelete(row.original)}
              handleView={() => handleView(row.original)}
            />
          </div>
        ),
      },
      {
        Header: 'Transaction ID',
        accessor: 'transactionId',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Amount ($)',
        accessor: 'amount',
      },
      {
        Header: 'User',
        Cell: ({ row }: any) => {
          return (
            <span>
              {row.original.user?.firstName} {row.original.user?.lastName}
            </span>
          );
        },
      },
      {
        Header: 'Date/ Time',
        Cell: ({ row }: any) => {
          return <span>{formatDate(new Date(row.original.createdAt))}</span>;
        },
        // accessor: 'createdAt',
      },
      {
        Header: 'Status',
        // accessor: 'status',
        Cell: ({ row }: any) => {
          return (
            <span
              className={
                row.original.status === 'COMPLETED'
                  ? 'text-green-500'
                  : 'text-red-500'
              }
            >
              {row.original.status}
            </span>
          );
        },
      },
    ],
    [],
  );

  return (
    <div>
      <Head title='Transactions' />
      {isLoading ? (
        <div className='flex h-screen justify-center items-center'>
          <Spinner size='md' />
        </div>
      ) : (
        <div className='overflow-hidden '>
          <Table
            data={transactions ?? []}
            columns={columns}
            showPagination={true}
          />
        </div>
      )}
    </div>
  );
};
