import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  cancelCashout,
  getCashout,
  getTransactions,
  paidCashout,
} from '../api';

export const useGetTransactions = () => {
  return useQuery({
    queryKey: ['transactions'],
    queryFn: getTransactions,
    //refetchOnWindowFocus: 'always',
    staleTime: 30 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    select: (data) => {
      return data.data.resource;
    },
  });
};

export const useGetCashout = () => {
  return useQuery({
    queryKey: ['cashouts'],
    queryFn: getCashout,
    staleTime: 30 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    select: (data) => {
      return data.data.resource;
    },
  });
};

export const usePaidCashout = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: paidCashout,
    onSuccess: (data: any, { cashoutID }) => {
      queryClient.setQueryData(['cashouts'], (oldQueryData: any) => {
        const updatedItem = oldQueryData.data.resource.find(
          (item: any) => item._id === cashoutID,
        );

        return {
          ...oldQueryData,
          data: {
            ...oldQueryData.data,
            resource: [
              { ...updatedItem, isPaid: true, status: 'COMPLETED' },
              ...oldQueryData.data.resource.filter(
                (item: any) => item._id !== cashoutID,
              ),
            ],
          },
        };
      });
    },
    onError: (error) => {
      console.log({ error });
    },
  });
};

export const useCancelCashout = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: cancelCashout,
    onSuccess: (data: any, _id: string) => {
      queryClient.setQueryData(['cashouts'], (oldQueryData: any) => {
        const updatedItem = oldQueryData.data.resource.find(
          (item: any) => item._id === _id,
        );

        return {
          ...oldQueryData,
          data: {
            ...oldQueryData.data,
            resource: [
              { ...updatedItem, isCanceled: true, status: 'FAILED' },
              ...oldQueryData.data.resource.filter(
                (item: any) => item._id !== _id,
              ),
            ],
          },
        };
      });
    },
    onError: (error) => {
      console.log({ error });
    },
  });
};
