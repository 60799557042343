import { useNavigate } from 'react-router-dom';

import { cuate } from '@/assets';

interface InterfaceProps {
  bgColor: string;
  h1Content: string;
  pContent: string;
  page: string;
  buttonBg: string;
}

export const Hero = ({
  bgColor,
  h1Content,
  pContent,
  page,
  buttonBg,
}: InterfaceProps) => {
  const navigate = useNavigate();

  return (
    <div className={`h-[45vh] md:h-[90vh] ${bgColor} flex px-8`}>
      <div
        className={`h-full w-full flex flex-col justify-center ${
          page === 'about' ? 'items-center sm:px-[60px]' : 'flex-1'
        }`}
      >
        {page === 'about' ? (
          <div className='flex'>
            <span className='font-bold text-4xl md:text-6xl mr-2 text-tranform: uppercase text-[indigo]'>
              About
            </span>
            <span className='font-bold text-4xl md:text-6xl text-tranform: uppercase text-[#FFC727]'>
              Us
            </span>
          </div>
        ) : (
          <h1 className='font-bold text-4xl md:text-6xl'>{h1Content}</h1>
        )}

        <p className='my-8 text-[12px] md:text-[16px]'>{pContent}</p>

        <button
          onClick={() => navigate(`/portal/invest`)}
          type='button'
          className={`${
            page === 'landing' ? 'hover:bg-yellow' : 'hover:bg-indigo'
          } ${buttonBg} text-white rounded w-36 md:w-48 p-1 md: py-2 mb-4 text-[14px] md:text-[16px] text-transform: uppercase font-medium`}
        >
          Start Investing
        </button>
      </div>
      {page === 'landing' ? (
        <div className='flex-1 h-full hidden md:flex flex-col justify-center items-center'>
          <img alt='hero-img' src={cuate} className='w-80' />
        </div>
      ) : null}
    </div>
  );
};
