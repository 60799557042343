import { food, apartment, crypto } from '@/assets';

export const Investments = () => {
  return (
    <div className='px-8 md:p-8'>
      <h2 className='text-lg font-semibold text-center mb-8 text-transform: uppercase'>
        What we invest in
      </h2>

      <div className='flex flex-col sm:flex-row sm:min-h-[30vh] px-4 sm:px-16 py-8 bg-[#D2FFDC] mb-8 rounded-md'>
        <div className='flex-1 sm:flex-[0.4]'>
          <img alt='food' src={food} className='h-[150px]' />
        </div>

        <div className='flex-1 sm:flex-[0.6]'>
          <h3 className='font-medium text-lg mb-2'>Agriculture</h3>
          <p>
            We invest a higher percentage of our capital in farm produce like
            rice, yam, and oil, with a goal of generating approximately $50,000
            in monthly revenue through farm development and agro-industry
            construction.
          </p>
        </div>
      </div>

      <div className='flex flex-col-reverse sm:flex-row sm:min-h-[30vh] px-4 sm:px-16 py-8  bg-[#DAEDFF] mb-8 rounded-md'>
        <div className='flex-1 sm:flex-[0.6]'>
          <h3 className='font-medium text-lg mb-2'>Real Estate</h3>
          <p>
            Landed property is a stable asset. We buy, develop, and manage
            properties in various locations. We make profits by purchasing at
            lower prices and selling at higher rates through our real estate
            network and expertise.
          </p>
        </div>
        <div className='flex flex-[0.4] justify-end'>
          <img
            alt='apartment'
            src={apartment}
            className='-mt-[55px] h-[150px]'
          />
        </div>
      </div>

      <div className='flex flex-col sm:flex-row  sm:min-h-[30vh] px-4 sm:px-16 py-8 bg-[#FFF9E2] rounded-md'>
        <div className='flex-[0.4]'>
          <img alt='crypto' src={crypto} className='h-[150px]' />
        </div>

        <div className='flex-[0.6]'>
          <h3 className='font-medium text-lg mb-2'>Forex</h3>
          <p>
            Digital assets like forex and crypto trading have long proven to be
            lucrative. We allocate part of our capital to foreign and crypto
            exchange markets, leveraging our expert team and analytical tools to
            generate profits.
          </p>
        </div>
      </div>
    </div>
  );
};
