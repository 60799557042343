import { useMutation, useQuery } from '@tanstack/react-query';

import {
  addWallet,
  cashout,
  convertFunds,
  deleteWallet,
  deposit,
  getUserByWalletId,
  getUserTransacactions,
  getUserWallet,
  transferFunds,
  getAllWallets,
} from '../api';

export const useGetTransactions = () => {
  return useQuery({
    queryKey: ['transactions'],
    queryFn: getUserTransacactions,
    //  refetchOnWindowFocus: 'always',
    staleTime: 30 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    select: (data) => {
      return data.data.resource.map((transaction: any) => ({
        ...transaction,
        amount: transaction.amount.toLocaleString(),
      }));
    },
  });
};

export const useGetWallet = () => {
  return useQuery({
    queryKey: ['wallet'],
    queryFn: getUserWallet,
    //refetchOnWindowFocus: 'always',
    staleTime: 30 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    select: (data) => {
      return data.data.resource;
    },
  });
};

export const useDeposit = () => {
  return useMutation({
    mutationFn: deposit,
  });
};

export const useTransferFunds = () => {
  return useMutation({
    mutationFn: transferFunds,
  });
};

export const useConvertFunds = () => {
  return useMutation({
    mutationFn: convertFunds,
  });
};

export const useCashout = () => {
  return useMutation({
    mutationFn: cashout,
  });
};

export const useAddWallet = () => {
  return useMutation({
    mutationFn: addWallet,
  });
};

export const useGetUserByWalletId = () => {
  return useMutation({
    mutationFn: getUserByWalletId,
  });
};

export const useDeleteWallet = () => {
  return useMutation({
    mutationFn: deleteWallet,
  });
};

export const useGetAllWallets = () => {
  return useQuery({
    queryKey: ['wallets'],
    queryFn: getAllWallets,
    //refetchOnWindowFocus: 'always',
    staleTime: 30 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    select: (data) => {
      return data.data.resource;
    },
  });
};
