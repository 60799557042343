import { useState, useEffect } from 'react';

import { Head } from '@/components/Head';
import { Footer } from '@/components/Layout/Footer';
import { Navbar } from '@/components/Layout/Navbar';
import storage from '@/utils/storage';

import { FAQ } from '../components/FAQ';
import { Guide } from '../components/Guide';
import { Investments } from '../components/Investments';
import { LandingHero } from '../components/LandingHero';
import { Offer } from '../components/Offer';
import { Support } from '../components/Support';
import { WhyUs } from '../components/WhyUs';

export const Landing = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const token = storage.getToken();
  useEffect(() => {
    setIsLoggedIn(token ? true : false);
  }, [token]);

  return (
    <>
      <Head title='Home' />
      <div className='min-h-[100vh]'>
        <Navbar bgColor='bg-cream' />
        <LandingHero />
        <Guide />
        <WhyUs />
        <Investments />
        <Offer />
        <Support />
        <FAQ />
        <Footer isLoggedIn={isLoggedIn} />
      </div>
    </>
  );
};
