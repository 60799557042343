import React from 'react';
import './HorizontalBarRating.css';

export const HorizontalBarRating = ({ rating }: any) => {
  const MAX_RATING = 5;
  const starPercentage = (rating / MAX_RATING) * 100;

  return (
    <div className='horizontal-bar-rating'>
      <div className='bar mr-2 rounded-md'>
        <div
          className='fill rounded-md'
          style={{ width: `${starPercentage}%` }}
        ></div>
      </div>
      <div className='stars'>
        {Array.from({ length: MAX_RATING }, (_, index) => (
          <span
            key={index}
            className={`star ${index < rating ? 'filled' : ''}`}
          >
            &#9733;
          </span>
        ))}
      </div>
      <div>
        <span className='text-indigo'>{Math.round(starPercentage)}%</span>
      </div>
    </div>
  );
};
