import Axios from 'axios';

import { API_URL } from '@/config';
import storage from '@/utils/storage';

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(
  async (config) => {
    const token = await storage.getToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response?.status == 401 &&
      !window.location.pathname.includes('auth')
    ) {
      storage.clearToken();
      storage.clearUser();
      window.location.assign('/auth/signin');
    }

    return Promise.reject(error);
  },
);
