import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { logo, logoDark, prevLogo } from '@/assets';
import { Head } from '@/components/Head';
import { ErrorProps } from '@/types';
import { scrollToTop } from '@/utils/functions';
import { notifyError, notifySuccess } from '@/utils/notifications';

import { SignInForm } from '../components/SignInForm';
import { useLogin } from '../hooks';

export const SignIn = () => {
  const navigate = useNavigate();

  const {
    mutate: signIn,
    isError,
    error,
    isLoading,
    isSuccess,
    reset,
  } = useLogin();

  if (isError) {
    const errorMsg =
      (error as ErrorProps).response.data.errors[0].msg ||
      (error as ErrorProps).message;

    scrollToTop();
    notifyError(errorMsg, 3000);

    if (errorMsg === 'You need to verify you account to login') {
      setTimeout(() => {
        navigate('/auth/verify-account');
      }, 3000);
    }
    reset();
  }

  if (isSuccess) {
    scrollToTop();
    notifySuccess('Login successful.', false);
    reset();
  }

  return (
    <div className='bg-login min-h-[100vh] bg-cream flex flex-col items-center justify-center'>
      <Head title='Sign In' />
      <Link to='/'>
        <img src={prevLogo} className='h-8' alt='logo' />
      </Link>

      <h2 className='my-4 font-medium text-indigo'>Welcome Back!</h2>
      <div className='shadow-all-sides rounded-lg w-[80vw]  md:w-[40vw] h-[60vh] p-4 flex flex-col items-center'>
        <h3 className='text-center mb-4 font-medium text-indigo'>Sign In</h3>

        <SignInForm
          signIn={signIn}
          isError={isError}
          error={error as ErrorProps}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
      </div>
      <ToastContainer />
    </div>
  );
};
