/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import { openIcon, closeIcon } from '@/assets';

interface FaqItem {
  question: string;
  answer: string;
}

export const FAQ: React.FC = () => {
  const [faqData] = useState<FaqItem[]>([
    {
      question: 'What is AWAP INVESTORS LTD all about?',
      answer:
        'AWAP INVESTORS LTD is a global investment and business community that base on AGRICULTURE, REAL ESTATE AND FOREX. We offers various financial & business services, which includes investing, loans, savings, digital shares, business mentorship, crypto trading and digital skills academy.',
    },
    {
      question: 'How can I register and invest?',
      answer:
        'Get a referral link from your sponsor/upline or click the sign up button in the website, enter your accurate details and register, check your email and verify, log in, deposit or transfer to account, activate with $5 to access all the web facilities and invest in any package.',
    },
    {
      question: "What is the company's return of investment (ROI)?",
      answer:
        'The company have 10% - 14% weekly return of investment and 200% total return of investment including all other bonuses, incentives and networkers salary.',
    },
    {
      question: 'What is the company minimum investment?',
      answer:
        'Our company runs an inclusive investment packages ranging from $20 - $5,000 depending on the selected package but users can invest as low as $5 in our digital shares. multiple packages allowed.',
    },
    {
      question: 'How can I withdraw my investment?',
      answer:
        'Investors can withdraw direct to their bank account or wallet address using the website cash out facility or preferably transfer to other users in exchange for cash. pay out day is every friday.',
    },
    {
      question:
        'Do AWAP INVESTORS LTD have a referral system and is it compulsory?',
      answer:
        "Yes, our company have referral system but it's optional, non networkers can invest and earn their proposed ROI. Referring gives more income, incentives, networkers salary and 2% - 8% referral bonus.",
    },
    {
      question: 'What is the company loan interest rate & how to apply?',
      answer:
        'Our company gives loans to users according your eligibility status and gets approval upon qualification with 10% monthly interest and 60% annual interest, To apply log in to the user dashboard and apply.',
    },
    {
      question: 'How Can I repay my loan?',
      answer:
        'Our company automatically deduct the money base on the monthly proportions, just deposit or transfer money to your profit balance wallet. or manually select the months you wish to repay and pay.',
    },
    {
      question: 'Is AWAP INVESTORS LTD registered and legal?',
      answer:
        'Yes, our company is officially registered under Nigeria corporate affairs commission (CAC) and licensed by Nigeria Export promotion council (NEPC).',
    },
    {
      question: 'What savings options do the company offer and interest?',
      answer:
        'Our company have saving facility for users with monthly interest depending on the plan. fixed and regular plan have 2% and 0% monthly interest respectively.',
    },
    {
      question: 'How can I withdraw my savings?',
      answer:
        'Upon maturity by completion of target or duration for regular and fixed plan, the withdrawal button is enabled and you withdraw direct to your profit balance wallet.',
    },
    {
      question:
        'What is difference between profit balance and reserved capital balance?',
      answer:
        'The profit balance wallet is withdrawable to your bank account or any other external wallet while reserved capital is used for investments or can be transferred within the AWAP system.',
    },
    {
      question: 'What is digital shares and how do I buy?',
      answer:
        "Digital shares are the company's digital shares which give investors access to ownership in the company and participate in the profit sharing. Deposit or transfer into your profit balance wallet and click the share market buy from the company or from investors at the share's market.",
    },
    {
      question: 'How do I withdraw or sell my shares?',
      answer:
        'Digital shares are frozen for 6 months, once matured push it to share market and sell, receive your money on your profit balance wallet and cashout.',
    },
  ]);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const toggleAccordion = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const filteredFaqData = faqData.filter((faq) =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div className='max-w-lg mx-auto flex flex-col items-center w-full my-8'>
      <input
        type='text'
        placeholder='Search FAQs'
        className='w-[80%] py-2 px-4 mb-4 border-none shadow-all-sides rounded-full focus:outline-none focus:border-blue-400'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <div className='w-full px-8 sm:px-0'>
        {filteredFaqData.map((item, index) => (
          <div key={index} className=''>
            <div
              className='flex items-center justify-between py-4 bg-white rounded-lg cursor-pointer'
              onClick={() => toggleAccordion(index)}
            >
              <div className='flex items-center font-semibold text-md text-indigo border-b-[1px]  w-full pb-2'>
                <img
                  src={index === activeIndex ? closeIcon : openIcon}
                  alt='icon'
                  className='mr-2 h-4 w-4'
                />
                {item.question}
              </div>
            </div>
            {activeIndex === index && (
              <div className='p4 '>
                <p className='text-gray-700'>{item.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
