import { Dialog, Transition } from '@headlessui/react';
//import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

type ModalProps = {
  isOpen?: boolean;
  closeModal?: any; //(val: boolean) => void;
  title?: string;
  children: React.ReactNode;
};

export const Modal = ({ isOpen, closeModal, children }: ModalProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='relative'>
                <button
                  onClick={closeModal}
                  className='shadow-all-sides absolute -top-4 -right-4 z-50 bg-cream p-2 rounded-full text-gray-500 hover:text-gray-700 cursor-pointer'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-5 w-5'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>

                <Dialog.Panel className='checkout-bg flex flex-col sm:flex-row min-h-[60vh] w-[90vw] sm:[w-70vw] md:w-[60vw] transform overflow-hidden rounded-2xl bg-cream p-6 text-left align-middle shadow-all-sides transition-all'>
                  {children}
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

// export const Modal = ({ isOpen, closeModal, title, children }: ModalProps) => {
//   return (
//     <>
//       <Transition appear show={isOpen} as={Fragment}>
//         <Dialog as='div' className='relative z-10' onClose={closeModal}>
//           <Transition.Child
//             as={Fragment}
//             enter='ease-out duration-300'
//             enterFrom='opacity-0'
//             enterTo='opacity-100'
//             leave='ease-in duration-200'
//             leaveFrom='opacity-100'
//             leaveTo='opacity-0'
//           >
//             <div className='fixed inset-0 bg-black bg-opacity-25' />
//           </Transition.Child>

//           <div className='fixed inset-0 overflow-auto'>
//             <div className='flex min-h-full items-center justify-center p-4 text-center'>
//               <Transition.Child
//                 as={Fragment}
//                 enter='ease-out duration-300'
//                 enterFrom='opacity-0 scale-95'
//                 enterTo='opacity-100 scale-100'
//                 leave='ease-in duration-200'
//                 leaveFrom='opacity-100 scale-100'
//                 leaveTo='opacity-0 scale-95'
//               >
//                 <div className='relative'>
//                   <button
//                     onClick={closeModal}
//                     className='shadow-all-sides absolute -top-4 -right-4 z-50 bg-cream p-2 rounded-full text-gray-500 hover:text-gray-700 cursor-pointer'
//                   >
//                     <svg
//                       xmlns='http://www.w3.org/2000/svg'
//                       className='h-5 w-5'
//                       fill='none'
//                       viewBox='0 0 24 24'
//                       stroke='currentColor'
//                     >
//                       <path
//                         strokeLinecap='round'
//                         strokeLinejoin='round'
//                         strokeWidth='2'
//                         d='M6 18L18 6M6 6l12 12'
//                       />
//                     </svg>
//                   </button>

//                   <Dialog.Panel className='w-full max-w-md md:max-w-[60%] transform rounded-md bg-slate-50 text-left align-middle shadow-xl transition-all'>
//                     <Dialog.Title
//                       as='h3'
//                       className='text-md font-medium leading-6 text-slate-500 h-[50px] bg-white flex items-center px-5 border-b-[1px] border-slate-200'
//                     >
//                       <span className='flex-grow'>{title}</span>
//                       <button
//                         onClick={() => closeModal(false)}
//                         className='text-slate-400 hover:text-slate-500 transition-colors'
//                       >
//                         <XMarkIcon className='h-5 w-5' />
//                       </button>
//                     </Dialog.Title>
//                     <>{children}</>
//                   </Dialog.Panel>
//                 </div>
//               </Transition.Child>
//             </div>
//           </div>
//         </Dialog>
//       </Transition>
//     </>
//   );
// };
