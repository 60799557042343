import { API_MOCKING } from '@/config';

import { worker } from './browser';
import { server } from './server';

export const initMocks = () => {
  if (API_MOCKING === true) {
    if (typeof window === 'undefined') {
      server.listen();
    } else {
      worker.start();
    }
  }
};
