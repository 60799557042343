import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { logo } from '@/assets';
import { Head } from '@/components/Head';
import { ErrorProps } from '@/types';
import { scrollToTop } from '@/utils/functions';
import { notifyError, notifySuccess } from '@/utils/notifications';

import { VerifyAccountForm } from '../components/VerifyAccountForm';
import { useVerifyAccount } from '../hooks';

export const VerifyAccount = () => {
  const navigate = useNavigate();

  const {
    mutate: verifyAccount,
    isError,
    error,
    isLoading,
    isSuccess,
    reset,
  } = useVerifyAccount();

  if (isError) {
    scrollToTop();
    notifyError(
      (error as ErrorProps).response.data.errors[0].msg ||
        (error as ErrorProps).message,
      false,
    );
    reset();
  }

  if (isSuccess) {
    scrollToTop();
    const timeout = 2000;
    notifySuccess('You account has been verified successfully.', timeout);
    reset();
    setTimeout(() => navigate('/auth/signin'), timeout);
  }

  return (
    <div className='bg-login min-h-[100vh] bg-cream flex flex-col items-center justify-center'>
      <Head title='Verify Account' />
      <Link to='/'>
        <img src={logo} className='h-8' alt='logo' />
      </Link>

      <h2 className='my-4 font-medium text-green-500'>
        Enter the Verification Code sent to your email
      </h2>
      <div className='shadow-all-sides rounded-lg w-[80vw]  md:w-[40vw] min-h-[20vh] p-4 flex flex-col items-center'>
        <VerifyAccountForm
          verifyAccount={verifyAccount}
          isError={isError}
          error={error as ErrorProps}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
      </div>
      <ToastContainer />
    </div>
  );
};
