import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { TypeOf, object, string } from 'zod';

type CheckoutInput = TypeOf<typeof checkoutSchema>;

const checkoutSchema = object({
  cardNumber: string().min(1, 'Card No. is required'),
  expiryMonth: string().min(1, 'Expiry month is required'),

  expiryYear: string().min(1, 'Expiry year should be 2023 or after'),

  cvv: string().min(1, 'CVV is required'),
});

const CheckoutForm = ({ closeForm }: { closeForm: () => void }) => {
  const {
    register,
    formState: { errors },

    handleSubmit,
  } = useForm<CheckoutInput>({
    resolver: zodResolver(checkoutSchema),
  });

  const onSubmit = (data: any) => {
    closeForm();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='max-w-lg mx-auto'>
      <div className='mb-4'>
        <label
          htmlFor='cardNumber'
          className='block text-sm font-medium text-indigo'
        >
          Card Number
        </label>
        <input
          {...register('cardNumber')}
          id='cardNumber'
          type='text'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          placeholder='1234 5678 9012 3456'
        />
        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.cardNumber?.message as string}
        </p>
      </div>
      <div className='mb-4 grid grid-cols-2 gap-4'>
        <div>
          <label
            htmlFor='expiryMonth'
            className='block text-sm font-medium text-indigo'
          >
            Expiry Month
          </label>

          <input
            {...register('expiryMonth')}
            id='expiryMonth'
            type='text'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
            placeholder='MM'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.expiryMonth?.message as string}
          </p>
        </div>
        <div>
          <label
            htmlFor='expiryYear'
            className='block text-sm font-medium text-indigo'
          >
            Expiry Year
          </label>

          <input
            {...register('expiryYear')}
            id='expiryYear'
            type='text'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
            placeholder='YY'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.expiryYear?.message as string}
          </p>
        </div>
      </div>
      <div className='mb-4'>
        <label htmlFor='cvv' className='block text-sm font-medium text-indigo'>
          CVV
        </label>

        <input
          {...register('cvv')}
          id='cvv'
          type='text'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          placeholder='123'
        />

        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.cvv?.message as string}
        </p>
      </div>
      <div className='mt-16'>
        <button
          type='submit'
          className='flex items-center justify-center bg-indigo hover:bg-yellow text-sm h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase shadow-all-sides'
        >
          Pay Now
        </button>
      </div>
    </form>
  );
};

export default CheckoutForm;
