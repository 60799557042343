import { ToastContainer } from 'react-toastify';

import { Head } from '@/components/Head';

import { ClassCategories } from '../components/Categories';
import { Header } from '../components/Header';
import { Subscription } from '../components/Subscription';

export const Mentorship = () => {
  return (
    <div>
      <Head title='Mentorship' />
      <Header />
      <Subscription />
      <div className='flex flex-col w-full '>
        <h1 className='text-center text-xl text-indigo font-bold mb-1'>
          Business Class Categories
        </h1>
        <div className='mb-16'>
          <ClassCategories />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
