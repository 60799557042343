import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { logo } from '@/assets';
import { Head } from '@/components/Head';
import { ErrorProps } from '@/types';
import { scrollToTop } from '@/utils/functions';
import { notifyError, notifySuccess } from '@/utils/notifications';

import { SignUpForm } from '../components/SignUpForm';
import { useRegister } from '../hooks';

export const SignUp = () => {
  const navigate = useNavigate();

  const {
    mutate: signUp,
    isError,
    error,
    isLoading,
    isSuccess,
    reset,
  } = useRegister();

  if (isError) {
    scrollToTop();
    notifyError(
      (error as ErrorProps).response.data.errors[0].msg ||
        (error as ErrorProps).message,
      false,
    );

    reset();
  }

  if (isSuccess) {
    scrollToTop();
    const timeout = 4000;
    notifySuccess(
      'You account has been created successfully. Check your email for your activation code',
      timeout,
    );
    reset();
    setTimeout(() => navigate('/auth/verify-account'), timeout);
  }

  return (
    <div className='p-8 bg-signup min-h-[100vh] bg-cream flex flex-col items-center justify-center'>
      <Head title='Sign Up' />
      <Link to='/'>
        <img src={logo} className='h-8' alt='logo' />
      </Link>

      <h2 className='my-4 font-medium text-indigo'>Welcome!</h2>
      <div className='shadow-all-sides rounded-lg w-[80vw]  md:w-[40vw] min-h-[60vh] p-4 flex flex-col items-center bg-cream'>
        <h3 className='text-center mb-4 font-medium text-indigo'>Sign Up</h3>

        <SignUpForm
          signUp={signUp}
          isError={isError}
          error={error as ErrorProps}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
      </div>
      <ToastContainer />
    </div>
  );
};
