import { zodResolver } from '@hookform/resolvers/zod';
import country, { Country } from 'country-list';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput, {
  Country as CountryCode,
  parsePhoneNumber,
  getCountryCallingCode,
} from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import Select from 'react-select';
import { TypeOf, object, string } from 'zod';

import { Spinner } from '@/components/Elements';
import { ErrorProps } from '@/types';
import lookupCountry from '@/utils/lookupCountry';
import { notifyError, notifySuccess } from '@/utils/notifications';

import { useEnrol } from '../hooks';

import 'react-phone-number-input/style.css';

export type EnrolInput = TypeOf<typeof enrolSchema>;

export type ClassCategory = 'Beginner' | 'Intermediate' | 'Advanced' | '3IN1';

type EnrolmentFormProps = {
  closeForm: () => void;
  classCategory: ClassCategory | '';
};

const enrolSchema = object({
  name: string().min(1, 'Name is required'),
  email: string().email({}).min(1, 'Email is required'),
  country: string({ required_error: 'Country is required' }).min(1),
  classType: string().min(1, 'Class Category is required'),
  whatsAppNumber: string({ required_error: 'WhatsApp Number is required' }).min(
    1,
  ),
});

export const EnrolmentForm: React.FC<EnrolmentFormProps> = ({
  closeForm,
  classCategory,
}) => {
  const [selectedCountry, setSelectedCountry] = useState<Country | string>(
    'NG',
  );
  const countryList = country.getData();

  const handleCountryChange = (value: string) => {
    setSelectedCountry(value);
  };

  //https://blog.logrocket.com/detecting-location-react-phone-number-input/
  async function handleNavigator(pos: any) {
    const { latitude, longitude } = pos.coords;

    const userCountryCode = await lookupCountry({ latitude, longitude });
    setSelectedCountry(userCountryCode);
  }
  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(handleNavigator, () =>
  //     console.warn('permission was rejected'),
  //   );
  // }, []);

  useEffect(() => {
    console.log('country changed');
  }, [selectedCountry]);

  const {
    mutate: enrol,
    isError,
    isLoading,
    isSuccess,
    error,
    data,
    reset,
  } = useEnrol();

  if (isSuccess) {
    const payment_url = data.data.resource.paymentUrl;
    notifySuccess(data.data.msg, false);
    if (payment_url) {
      window.open(payment_url, '_blank');
      closeForm();
    }

    reset();
  }

  if (isError) {
    notifyError(
      (error as ErrorProps).response.data.errors[0].msg ||
        (error as ErrorProps).message,
      2000,
    );
    reset();
  }

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<EnrolInput>({
    resolver: zodResolver(enrolSchema),
    defaultValues: {
      classType: classCategory,
      country: selectedCountry as string,
    },
  });

  const onSubmit = async (data: EnrolInput) => {
    await enrol(data);
  };

  return (
    <div className='flex flex-col w-full'>
      <h3 className='text-md text-indigo font-semibold text-transform: uppercase mb-4 text-center'>
        Enrol for the {classCategory} Class
      </h3>

      <form onSubmit={handleSubmit(onSubmit)} className=''>
        <div className='mb-4'>
          <label
            htmlFor='name'
            className='block text-sm font-medium text-indigo mb-1'
          >
            Name
          </label>
          <input
            {...register('name')}
            id='name'
            type='text'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.name?.message as string}
          </p>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='email'
            className='block text-sm font-medium text-indigo mb-1'
          >
            Email
          </label>
          <input
            {...register('email')}
            id='email'
            type='email'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.email?.message as string}
          </p>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='country'
            className='block text-sm font-medium text-indigo mb-1'
          >
            Country
          </label>
          <Controller
            name='country'
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Select
                  closeMenuOnSelect={true}
                  classNamePrefix='country'
                  className='w-full  text-indigo'
                  id='country'
                  getOptionLabel={(option: Country) => option.name}
                  getOptionValue={(option: Country) => option.code}
                  options={countryList}
                  defaultValue={countryList.find(
                    (country) => country.code === selectedCountry,
                  )}
                  onChange={(option: any) => {
                    onChange(option.code);
                    handleCountryChange(option.code);
                  }}
                  placeholder='Select Country'
                />
              );
            }}
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.country?.message as string}
          </p>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='whatsAppNumber'
            className='block text-sm font-medium text-indigo mb-1'
          >
            WhatsApp Number
          </label>

          <Controller
            name='whatsAppNumber'
            control={control}
            render={({ field }) => {
              return (
                <PhoneInput
                  {...field}
                  defaultCountry={selectedCountry as CountryCode}
                  className='rounded-md phone-input'
                  onCountryChange={(country) => {
                    handleCountryChange(country as string);
                  }}
                  limitMaxLength={true}
                  international={true}
                />
              );
            }}
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.whatsAppNumber?.message as string}
          </p>
        </div>

        <input
          {...register('classType')}
          id='classType'
          type='text'
          className='hidden mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />

        <div className='mt-16 flex justify-center'>
          <button
            type='submit'
            className='flex items-center justify-center bg-indigo hover:bg-yellow text-sm h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase shadow-all-sides'
          >
            {isLoading && <Spinner size='sm' className='text-white' />}
            <span className='mx-2'>Proceed</span>
          </button>
        </div>
      </form>
    </div>
  );
};
