import { useMutation, useQuery } from '@tanstack/react-query';

import { buyCrypto } from '../api/buyCrypto';
import { getCompanyConfig } from '../api/companyPrices';
import { getCryptoWallets } from '../api/cryptoWallets';
import { sellCrypto } from '../api/sellCrypto';

export const useGetCryptoWallets = () => {
  return useQuery({
    queryKey: ['cryptoWallets'],
    queryFn: getCryptoWallets,
    // staleTime: 30 * 60 * 1000,
    // cacheTime: 30 * 60 * 1000,
    refetchInterval: 1 * 60 * 1000,
    select: (data) => {
      return data.data?.resource;
    },
  });
};

export const useGetCompanyConfig = () => {
  return useQuery({
    queryKey: ['companyConfig'],
    queryFn: getCompanyConfig,
    // staleTime: 30 * 60 * 1000,
    // cacheTime: 30 * 60 * 1000,
    refetchInterval: 1 * 60 * 1000,
    select: (data) => {
      return data.data?.resource;
    },
  });
};

export const useBuyCrypto = () => {
  return useMutation({
    mutationFn: buyCrypto,
  });
};

export const useSellCrypto = () => {
  return useMutation({
    mutationFn: sellCrypto,
  });
};
