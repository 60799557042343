import { axios } from '@/lib/axios';

import { LoanInput } from '../components/LoanForm';
import { LoanRepayInput } from '../components/RepayLoanForm';

export const getUserLoans = async () => {
  const response = await axios.get('/loans/my-loans/all');
  return response.data;
};

export const applyForLoan = async (payload: LoanInput) => {
  const response = await axios.post(`/loans/`, {
    ...payload,
    numberOfMonth: Number(payload.numberOfMonth),
  });
  return response.data;
};

export const repayLoan = async ({
  loanID,
  payload,
}: {
  loanID: string;
  payload: LoanRepayInput;
}) => {
  const response = await axios.patch(`/loans/${loanID}/repay`, {
    numberOfMonth: Number(payload.numberOfMonth),
  });
  return response.data;
};

export const getAllLoans = async () => {
  const response = await axios.get('/loans?pageSize=5000');
  return response.data;
};
