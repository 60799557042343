import React from 'react';

export const BoardDirectors = () => {
  return (
    <div className='px-8 sm:p-8 mt-8 sm:my-4 mb-8'>
      <h2 className='text-lg font-semibold text-start mb-4 text-transform: uppercase text-[indigo]'>
        Board of Directors
      </h2>
    </div>
  );
};
