/* eslint-disable no-restricted-imports */
import omit from 'lodash/omit';
import { rest } from 'msw';
import { nanoid } from 'nanoid';

import { API_URL } from '@/config';
import { SignInInput } from '@/features/auth/components/SignInForm';
import { SignUpInput } from '@/features/auth/components/SignUpForm';
import { VerifyAccountInput } from '@/features/auth/components/VerifyAccountForm';

import { db, persistDb } from '../db';
import { authenticate, delayedResponse, hash } from '../utils';

export const authHandlers = [
  rest.post<SignUpInput>(`${API_URL}/users`, async (req, res, ctx) => {
    try {
      const userObject = req.body;

      const existingUser = db.user.findFirst({
        where: {
          email: {
            equals: userObject.email,
          },
        },
      });

      if (existingUser) {
        // throw new Error('The user already exists');
        return delayedResponse(
          ctx.status(400),
          ctx.json({
            statusCode: 400,
            responseText: 'FAIL',
            errors: [{ msg: `The user already exists` }],
          }),
        );
      }

      db.user.create({
        ...userObject,
        _id: nanoid(),
        password: hash(userObject.password),
        userType: 'USER',
      });

      persistDb('user');
      const result = {
        statusCode: 201,
        responseText: 'SUCCESS',
        data: {
          msg: 'User Created Successfully',
          resource: omit(userObject, ['password', 'confirmPassword']),
          extra: {},
        },
      };

      return delayedResponse(ctx.json(result));
    } catch (error: any) {
      return delayedResponse(
        ctx.status(400),
        ctx.json({ msg: error?.message || 'Server Error' }),
      );
    }
  }),

  rest.patch<VerifyAccountInput>(
    `${API_URL}/users/verify`,
    async (req, res, ctx) => {
      const result = {
        statusCode: 201,
        responseText: 'SUCCESS',
        data: {
          msg: 'User Created Successfully',
          //resource: omit(userObject, ['password', 'confirmPassword']),
          extra: {},
        },
      };

      return delayedResponse(ctx.json(result));
    },
  ),

  rest.post<SignInInput>(`${API_URL}/users/login`, async (req, res, ctx) => {
    try {
      const credentials = req.body;

      const result = await authenticate(credentials);

      return delayedResponse(ctx.json(result));
    } catch (error: any) {
      console.log(error);

      return delayedResponse(
        ctx.status(400),
        ctx.json({
          statusCode: 400,
          responseText: 'FAIL',
          errors: [{ msg: `Invalid user credentials` }],
        }),
      );
    }
  }),
];
