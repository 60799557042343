import { logoDark, fb, ig, twitter } from '@/assets';
import { contactDetails, getFooterLinks } from '@/constants/footerlinks';

export const Footer: React.FC<{ isLoggedIn?: boolean }> = ({ isLoggedIn }) => {
  const footerLinks = getFooterLinks(isLoggedIn ?? false);

  return (
    <div className='min-h-[40vh] px-2 py-8 md:p-16 bg-yellow flex-col md:flex md:flex-row'>
      <div className='logo-bg flex-[0.5] py-2 flex md:items-center md:justify-center px-8 md:px-0'>
        <img alt='logo-black' src={logoDark} className='w-32 md:w-52' />
      </div>
      <div className='flex-1 px-8 items-center justify-center align-middle'>
        <div className='flex-[1.5] w-full flex sm:flex-row flex-col justify-between flex-wrap md:mt-0 mt-4'>
          {footerLinks.map((item) => (
            <div className='mb-2' key={item.title}>
              <h3 className='text-white font-semibold mb-1'>{item.title}</h3>

              <ul>
                {item.children.map((data) => (
                  <li
                    className='text-white hover:text-blue-100 text-[12px] mb-[5px]'
                    key={data.title}
                  >
                    <a href={data.link}>{data.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className='mb-2'>
            <h3 className='text-white font-semibold mb-1'>
              {contactDetails.title}
            </h3>
            <ul>
              {contactDetails.details.map((data) => (
                <li
                  className='text-white text-[12px] mb-[5px]'
                  key={data.detail}
                >
                  <span className='text-white font-medium'>{data.detail}:</span>{' '}
                  {data.value}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='flex mt-2'>
          <a
            className=''
            href='https://www.facebook.com/Awapinvestors?mibextid=ZbWKwL'
          >
            <div className='h-8 w-8 rounded-full p-1 bg-cream hover:bg-blue-100 flex items-center justify-center mr-1'>
              <img alt='fb-icon' src={fb} className='h-4 w-4' />
            </div>
          </a>

          <a href='https://x.com/awapinvestors?t=IrvOt4rGltsR7AQzfFlnNA&s=08'>
            <div className='h-8 w-8 rounded-full p-1 bg-cream hover:bg-blue-100 flex items-center justify-center mr-1'>
              <img alt='twitter-icon' src={twitter} className='h-4 w-4' />
            </div>
          </a>

          <a href='https://www.instagram.com/awapinvestors?igsh=ZGUzMzM3NWJiOQ=='>
            <div className='h-8 w-8 rounded-full p-1 bg-cream hover:bg-blue-100 flex items-center justify-center mr-1'>
              <img alt='ig-icon' src={ig} className='h-4 w-4' />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
