import { Menu, Transition } from '@headlessui/react';
import {
  EllipsisVerticalIcon,
  PencilSquareIcon,
  DocumentMagnifyingGlassIcon,
  TrashIcon,
  LockClosedIcon,
  ClockIcon,
  XMarkIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import storage from '@/utils/storage';

type TableMenuProps = {
  _id: string;
  handlePaid: (_id: string) => void;
  handleCancel: (_id: string) => void;
  cashout: any;
};

export const TableMenu = ({
  _id,
  handlePaid,
  handleCancel,
  cashout,
}: TableMenuProps) => {
  return (
    <div className='text-right top-16 w-28'>
      <Menu as='div' className='inline-block text-left'>
        <div>
          <Menu.Button className='flex items-center justify-center w-8 h-8 bg-white shadow-md table-menu'>
            <EllipsisVerticalIcon className='h-5' />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute left-0 z-10 mt-2 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg w-36 ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='px-1 py-1'>
              {!cashout.isPaid && !cashout.isCanceled && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => handleCancel(_id)}
                      className={`${
                        active ? ' text-primary' : 'text-black'
                      } plan flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <XMarkIcon
                        className='w-4 h-4 mr-2'
                        color='red'
                        aria-hidden='true'
                      />
                      Cancel
                    </button>
                  )}
                </Menu.Item>
              )}

              {!cashout.isPaid && !cashout.isCanceled && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => handlePaid(_id)}
                      className={`${
                        active ? ' text-primary' : 'text-black'
                      } plan flex w-full items-center rounded-md px-2 py-2 text-sm delete-btn`}
                    >
                      <CheckIcon
                        className='w-4 h-4 mr-2'
                        color='green'
                        aria-hidden='true'
                      />
                      Paid
                    </button>
                  )}
                </Menu.Item>
              )}
              {(cashout.isPaid || cashout.isCanceled) && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? ' text-primary' : 'text-black'
                      } plan flex w-full items-center rounded-md px-2 py-2 text-sm delete-btn`}
                    >
                      <CheckIcon
                        className='w-4 h-4 mr-2'
                        color='green'
                        aria-hidden='true'
                      />
                      No Action
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
