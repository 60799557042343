import { btcCoin } from '@/assets';

export const Banner = () => {
  return (
    <div className='min-h-[20vh] rounded-md bg-black mb-8 flex justify-between items-center'>
      <div className='flex flex-col sm:flex-row flex-[0.5] sm:flex-[0.3]  px-4 py-6'>
        <h3 className='text-yellow text-xl font-semibold'>
          Buy & Sell Crypto With Ease
        </h3>

        <p className='text-white text-xs sm:hidden mt-2'>
          Buy crypto curencies like bitcoin and Etherium with ease using your
          Credit/Debit Card or buy Bank Transfers.
        </p>
      </div>

      <div className='flex flex-[0.5] sm:flex-[0.7] bg-btcCoin '>
        <div className='hidden sm:flex flex-[0.5] bg-btcText px-4 py-6 items-center justify-center'>
          <p className='text-white text-sm'>
            Buy crypto curencies like bitcoin and Etherium with ease using your
            Credit/Debit Card or buy Bank Transfers.
          </p>
        </div>

        <div className='flex sm:flex-[0.5]  px-4 py-6 justify-center'>
          <img className='h-[120px]' alt='btc coin' src={btcCoin} />
        </div>
      </div>
    </div>
  );
};
