import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';
import { TypeOf, object, string } from 'zod';

import { Spinner } from '@/components/Elements';
import { ErrorProps } from '@/types';
import { notifyError, notifySuccess } from '@/utils/notifications';

import { useSellCrypto } from '../hooks';
import { WalletAddress } from '../routes/BuyCrypto';

export type SellCryptoInput = TypeOf<typeof sellCryptoSchema>;

type SellCryptoFormProps = {
  closeForm: () => void;
  selectedCoin: any;
  setSelectedAddress: any;
  openPaymentModal: any;
  setAmount: any;
};
const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    overflowY: 'auto',
    maxHeight: '200px', // Adjust based on your needs
  }),
};

const sellCryptoSchema = object({
  amount: string().min(1, 'Amount is required'),
  email: string().min(1, 'Email is required'),
  coinType: string().min(1, 'Coin is Required'),
  network: string().min(1, 'Network is required'),
  bank: string().min(1, 'Bank Name is required'),
  accountNumber: string().min(1, 'Account Number is required'),
  name: string().min(1, 'Account Name is required'),
});

export const SellCryptoForm: React.FC<SellCryptoFormProps> = ({
  closeForm,
  selectedCoin,
  setSelectedAddress,
  openPaymentModal,
  setAmount,
}) => {
  const {
    mutate: sellCrypto,
    isError,
    isLoading,
    isSuccess,
    error,
    data,
    reset,
  } = useSellCrypto();

  if (isSuccess) {
    notifySuccess(data.data.msg, 3000);

    closeForm();

    reset();
    openPaymentModal();
  }

  if (isError) {
    notifyError(
      (error as ErrorProps).response.data.errors[0].msg ||
        (error as ErrorProps).message,
      2000,
    );
    reset();
  }

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<SellCryptoInput>({
    resolver: zodResolver(sellCryptoSchema),
    defaultValues: {
      coinType: selectedCoin?.coinShortName,
    },
  });

  const onSubmit = (data: SellCryptoInput) => {
    setAmount(data.amount);
    sellCrypto(data);
  };

  return (
    <div className='flex flex-col w-full'>
      <h3 className='text-md text-indigo font-medium text-transform: uppercase mb-4 text-center'>
        Sell Crypto
      </h3>

      <span className='text-sm text-indigo mb-4 text-center'>
        Sell Crypto to Awap Investments LTD
      </span>

      <form onSubmit={handleSubmit(onSubmit)} className=''>
        <div className='mb-4'>
          <label
            htmlFor='email'
            className='block text-sm font-medium text-indigo'
          >
            Email Address
          </label>
          <input
            {...register('email')}
            type='email'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.email?.message as string}
          </p>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='amount'
            className='block text-sm font-medium text-indigo'
          >
            Amount ($)
          </label>
          <input
            {...register('amount')}
            type='number'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.amount?.message as string}
          </p>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='coinType'
            className='block text-sm font-medium text-indigo'
          >
            Coin
          </label>
          <input
            {...register('coinType')}
            type='text'
            readOnly={true}
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.coinType?.message as string}
          </p>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='network'
            className='block text-sm font-medium text-indigo'
          >
            Network
          </label>
          {/* <input
            {...register('network')}
            type='text'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          /> */}

          <Controller
            name='network'
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Select
                  className='mt-1'
                  classNamePrefix='w-full'
                  id='network-select'
                  isSearchable={false}
                  // defaultValue={selectedCoin}
                  getOptionLabel={(option: WalletAddress) => option?.network}
                  getOptionValue={(option: WalletAddress) => option?.network}
                  options={
                    selectedCoin && selectedCoin.address.length
                      ? selectedCoin.address
                      : []
                  }
                  onChange={(option: SingleValue<WalletAddress>) => {
                    setSelectedAddress(option);
                    onChange(option?.network);
                  }}
                  styles={customStyles}
                />
              );
            }}
          />

          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.network?.message as string}
          </p>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='bank'
            className='block text-sm font-medium text-indigo'
          >
            Bank Name
          </label>
          <input
            {...register('bank')}
            type='text'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.bank?.message as string}
          </p>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='accountNumber'
            className='block text-sm font-medium text-indigo'
          >
            Account Number
          </label>
          <input
            {...register('accountNumber')}
            type='text'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.accountNumber?.message as string}
          </p>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='name'
            className='block text-sm font-medium text-indigo'
          >
            Account Name
          </label>
          <input
            {...register('name')}
            type='text'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.name?.message as string}
          </p>
        </div>

        <div className='mt-16 flex justify-center'>
          <button
            type='submit'
            className='flex items-center justify-center bg-indigo hover:bg-yellow text-sm h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase shadow-all-sides'
          >
            {isLoading && <Spinner size='sm' className='text-white' />}
            <span className='mx-2'>Proceed</span>
          </button>
        </div>
        {/* )} */}
      </form>
    </div>
  );
};
