import { toast } from 'react-toastify';

export const notifySuccess = (msg: string, autoClose: number | false) =>
  toast.success(msg, {
    position: 'top-right',
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });

export const notifyError = (msg: string, autoClose: number | false) =>
  toast.error(msg, {
    position: 'top-right',
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
