import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { TypeOf, object, string } from 'zod';

import { Spinner } from '@/components/Elements';
import { FormComponentProps } from '@/types';

export type UpdatePasswordInput = TypeOf<typeof UpdatePasswordSchema>;

type UpdatePasswordProps = {
  updatePassword: (data: UpdatePasswordInput) => void;
} & FormComponentProps;

const UpdatePasswordSchema = object({
  oldPassword: string().min(1, 'Old Password is required'),
  newPassword: string().min(1, 'New Password is required'),
  newConfirmPassword: string().min(1, 'Confirm Password is required'),
}).refine((data) => data.newPassword === data.newConfirmPassword, {
  message: "New password and New Confirm password don't match",
  path: ['newConfirmPassword'],
});

export const UpdatePasswordForm: React.FC<UpdatePasswordProps> = ({
  updatePassword,
  isError,
  isLoading,
  error,
}) => {
  const {
    register,
    formState: { errors },

    handleSubmit,
  } = useForm<UpdatePasswordInput>({
    resolver: zodResolver(UpdatePasswordSchema),
  });

  const onSubmit = async (data: UpdatePasswordInput) => {
    await updatePassword(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='w-[72vw] md:w-[32vw] mx-auto'
    >
      {isError ? (
        <div className='my-[1rem] text-sm font-semibold text-red-500 text-center'>{`${
          error.response?.data.errors[0].msg || error.message
        }`}</div>
      ) : null}
      <div className='mb-4'>
        <label
          htmlFor='oldPassword'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Enter Old Password
        </label>

        <input
          {...register('oldPassword')}
          id='oldPassword'
          type='password'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />

        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.oldPassword?.message as string}
        </p>
      </div>

      <div className='mb-4'>
        <label
          htmlFor='newPassword'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Chooose New Password
        </label>

        <input
          {...register('newPassword')}
          id='newPassword'
          type='password'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />

        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.newPassword?.message as string}
        </p>
      </div>

      <div>
        <label
          htmlFor='newConfirmPassword'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Confirm New Password
        </label>

        <input
          {...register('newConfirmPassword')}
          id='confirmPassword'
          type='password'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />

        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.newConfirmPassword?.message as string}
        </p>
      </div>

      <div className='mt-8 flex flex-col items-center justify-center'>
        <button
          type='submit'
          className='flex items-center justify-center bg-indigo hover:bg-yellow text-sm h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase shadow-all-sides'
        >
          {isLoading && <Spinner size='sm' className='text-white' />}
          <span className='mx-2'>Change Password</span>
        </button>

        <Link
          to='/auth/signin'
          className='font-sm text-sm text-[#0645AD] hover:text-indigo mt-2'
        >
          Sign In
        </Link>
      </div>
    </form>
  );
};
