import { bag, bill, dollarsearch } from '@/assets';

const Card = ({
  icon,
  title,
  content,
}: {
  icon: any;
  title: string;
  content: string;
}) => {
  return (
    <div>
      <div className='flex items-center mb-2'>
        <div className='p-2 mr-2 shadow-all-sides rounded-full'>
          <img className='h-6' alt='bill' src={icon} />
        </div>
        <h3 className=' font-medium text-md'>{title}</h3>
      </div>

      <div className='border-[1px] rounded-md mr:0 sm:mr-2 mb-4 sm:mb-0'>
        <p className='p-2 text-[12px]'>{content}</p>
      </div>
    </div>
  );
};

export const WhyUs = () => {
  return (
    <div className='px-8 mt-8 sm:mt-0 mb-8 sm:my-0 sm:p-8'>
      <h2 className='text-lg font-semibold text-center mb-8 text-transform: uppercase'>
        Why you should choose us as your investment partners
      </h2>

      <div className='flex flex-col sm:flex-row justify-between'>
        <Card
          title='Transparency'
          icon={bill}
          content='We prioritize transparency as a bedrock of the company by openly sharing information and actions which builds trust, holds organization accountable and enables informed decision-making. YES, WE ARE HONEST. TRUST US!'
        />
        <Card
          title='Digital Innovation'
          icon={dollarsearch}
          content='We always develop a strategic focus on leveraging technology and digital tools to drive creativity, efficiency and growth within our company.
          YES, WE HAVE TECHNOLOGY THAT WORKS!'
        />
        <Card
          title='Diversified Portfolio'
          icon={bag}
          content='By diversifying portfolio in a business organization  practice of spreading investments, assets, or revenue across a variety of different areas or sectors. This strategy is a core value because it helps mitigate risks associated with economic fluctuations or unforeseen events. By diversifying, a company reduces its dependence on a single source of income or market segment'
        />
      </div>
    </div>
  );
};
