export const Subscription = () => {
  return (
    <div className='px-8 mt-8 sm:mt-8 mb-8 sm:my-0 sm:p-8'>
      <div className='flex flex-col justify-center items-center max-w-[600px] m-auto'>
        {/* <h3 className='text-xl text-indigo font-medium mb-8'>
          Business Support Service
        </h3> */}

        <p className='mb-4 text-center'>
          We understand that navigating the world of business can be
          challenging. That&lsquo;s why we provide business support services to
          bridge the gap between your aspirations and your business&lsquo;s
          potential. Our team assists you in uncovering crucial insights,
          acquiring essential skills, and implementing necessary strategies for
          the growth of your enterprise.
        </p>

        {/* <div className='mb-8 flex h-[48px] sm:w-[480px] bg-slate-100 items-center justify-between rounded-md pl-2 pr-[1px] py-2 border-[1px]'>
          <input
            type='email'
            placeholder='ENTER YOUR EMAIL'
            className='h-[46px] w-full text-sm border-none bg-slate-100 outline-0 focus:outline-0 focus:border-none border-transparent focus:border-transparent focus:ring-0 number-input'
          />
          <button
            type='submit'
            className={`flex items-center justify-center bg-indigo hover:bg-yellow text-sm h-[44px] w-[240px] p-2 rounded-md text-white text-transform: uppercase shadow-all-sides`}
          >
            Subscribe
          </button>
        </div> */}
      </div>
    </div>
  );
};
