import { useState } from 'react';

import { ceo, visionary } from '@/assets';

type ParagraphNums =
  | 'one'
  | 'two'
  | 'three'
  | 'four'
  | 'five'
  | 'six'
  | 'seven'
  | 'eight';

const paragraphNums = [
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
];
const numOfParagraphs = 8;

export const Visionary = () => {
  const [openParagraphsIndex, setOpenParagraphsIndex] = useState(0);
  const [openParagraphs, setOpenParagraphs] = useState(['one']);

  const handleReadMoreOrLess = (action: 'more' | 'less') => {
    if (action === 'more') {
      const index = openParagraphsIndex + 1;
      if (index < numOfParagraphs) {
        const item = paragraphNums[index];

        setOpenParagraphs([...openParagraphs, item]);
        setOpenParagraphsIndex(index);
      }
    } else {
      setOpenParagraphsIndex(0);
      setOpenParagraphs(['one']);
    }
  };

  return (
    <div className='flex flex-col py-4 md:flex-row px-8 mx-8 mt-8 sm:mt-0 mb-8 sm:my-0 sm:p-8 min-h-[50vh] rounded-md bg-cream'>
      <div className='flex items-center md:items-start mb-2 md:mb-0 flex-[0.3] flex-col mr-6'>
        <img src={ceo} alt='ceo' className='w-80' />
        <h4 className='mt-4 text-md font-medium self-center text-transform: uppercase text-[indigo]'>
          Okorie Caleb Ezechinma
        </h4>
        <span className='text-[#A6A6A6] text-center'>
          Chief Executive Director AWAP INVESTORS LTD
        </span>
      </div>

      <div className='flex flex-col flex-[0.7] '>
        <h2 className='text-lg font-semibold text-start mb-4 text-transform: uppercase text-[indigo]'>
          The Visionary
        </h2>
        <p className='mb-4 one'>
          Comrade Okorie Caleb Ezechima (Ability) is a young leadership and
          entrepreneurship coach who hails from Amaogudu Uhuotaru Ugwulangwu,
          Ohaozara Local Government area of Ebonyi State, Nigeria. He was born
          on September 3, 1996.
        </p>

        <p
          className={`${
            openParagraphs.includes('two') ? 'block' : 'hidden'
          } mb-4`}
        >
          He obtained his first School Leaving Certificate (FSCL) at Ndiagu
          Primary School, Umuigboke Uhuotaru Ugwulawgwu and O’Level Certificates
          at Ugwulangwu Secondary School Ugwulagwu (USSU).
        </p>

        <p
          className={`${
            openParagraphs.includes('three') ? 'block' : 'hidden'
          } mb-4`}
        >
          He graduated from both Nigeria Army Institute of Technology and
          Environmental Studies (NAITES) in Benue State and Federal University
          of Technology, Owerri (FUTO) Imo State under civil Engineering.
        </p>

        <p
          className={`${
            openParagraphs.includes('four') ? 'block' : 'hidden'
          } mb-4`}
        >
          As a leader who is passionate about the welfare of the society, he has
          served the society in different levels and categories as he was once
          the Vice President of National Association of Civil Engineering
          Students (NACES) NAITES Chapter, and the founder and President of
          Ugwulangwu Scholars Forum (USF).
        </p>

        <p
          className={`${
            openParagraphs.includes('five') ? 'block' : 'hidden'
          } mb-4`}
        >
          As a scholar he have once served as the Secretary and president of
          Federated Ohaozara Student Association (FOSA), Ebonyi south zonal
          coordinator of national association of Nigeria Comrades [NANC] and
          currently the National President Of Ebonyi South Student Association
          [ESSA] and SA to the executive chairman of Ohaozara LGA on students
          welfare and development.
        </p>

        <p
          className={`${
            openParagraphs.includes('six') ? 'block' : 'hidden'
          } mb-4`}
        >
          Comrade Okorie Caleb Ezechima as a passionate trader, investor,
          business enthusiast and an entrepreneur who has worked and led many
          networking and Crypto Based Companies across the globe since 2016 and
          as well, one of the Regional Marketer at Omni Excel Ltd (A Mega Phone
          and Computer Accessories Company) with its headquarters in Lagos,
          Nigeria.
        </p>

        <p
          className={`${
            openParagraphs.includes('seven') ? 'block' : 'hidden'
          } mb-4`}
        >
          As an engineer, Okorie Caleb Ezechima is a trusted hand in the
          designing and construction of civil engineering projects like; roads,
          bridges, commercial and residential houses, etc. As a matter of fact,
          he has featured in the construction of several governmental and
          private civil engineering projects like; The famous Ebonyi State
          Vocational College, King David Gifted Academy, Many Ebonyi State UBEB
          projects and host of other strategic and mind blowing projects.
        </p>

        <p
          className={`${
            openParagraphs.includes('eight') ? 'block' : 'hidden'
          } mb-4`}
        >
          Comrade Okorie Caleb Ezechima is currently the Director General of
          AWAP GROUPS and the CEO, AWAP INVESTORS LTD
        </p>

        <button
          onClick={() =>
            handleReadMoreOrLess(
              openParagraphsIndex < numOfParagraphs - 1 ? 'more' : 'less',
            )
          }
          className='flex text-slate-500'
        >
          {openParagraphsIndex < numOfParagraphs - 1
            ? 'Read more'
            : 'Read less'}
        </button>
      </div>
    </div>
  );
};
