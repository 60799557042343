import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { TypeOf, object, string } from 'zod';

import { Spinner } from '@/components/Elements';
import { FormComponentProps } from '@/types';

export type ResetPasswordInput = TypeOf<typeof ResetPasswordSchema>;
type ResetPasswordProps = {
  resetPassword: (data: ResetPasswordInput) => void;
} & FormComponentProps;

const ResetPasswordSchema = object({
  newPassword: string().min(1, 'New Password is required'),
  passwordResetCode: string().min(1, 'Password Reset Code is required'),
});

export const ResetPasswordForm: React.FC<ResetPasswordProps> = ({
  resetPassword,
  isError,
  isLoading,
  error,
}) => {
  const {
    register,
    formState: { errors },

    handleSubmit,
  } = useForm<ResetPasswordInput>({
    resolver: zodResolver(ResetPasswordSchema),
  });

  const onSubmit = async (data: ResetPasswordInput) => {
    await resetPassword(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='w-[72vw] md:w-[32vw] mx-auto'
    >
      {isError ? (
        <div className='my-[1rem] text-sm font-semibold text-red-500 text-center'>{`${
          error.response?.data.errors[0].msg || error.message
        }`}</div>
      ) : null}
      <div className='mb-4'>
        <label
          htmlFor='newPassword'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Chooose New Password
        </label>

        <input
          {...register('newPassword')}
          id='newPassword'
          type='password'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />

        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.newPassword?.message as string}
        </p>
      </div>

      <div>
        <label
          htmlFor='passwordResetCode'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Enter Password Reset Code
        </label>

        <input
          {...register('passwordResetCode')}
          id='passwordResetCode'
          type='text'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />

        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.passwordResetCode?.message as string}
        </p>
      </div>

      <div className='mt-8 flex flex-col items-center justify-center'>
        <button
          type='submit'
          className='flex items-center justify-center bg-indigo hover:bg-yellow text-sm h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase shadow-all-sides'
        >
          {isLoading && <Spinner size='sm' className='text-white' />}
          <span className='mx-2'>Submit</span>
        </button>

        <Link
          to='/auth/signin'
          className='font-sm text-sm text-[#0645AD] hover:text-indigo mt-2'
        >
          Sign In
        </Link>
      </div>
    </form>
  );
};
