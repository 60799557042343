import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { TypeOf, object, string } from 'zod';

import { Spinner } from '@/components/Elements';
import { FormComponentProps } from '@/types';

export type VerifyAccountInput = TypeOf<typeof VerifyAccountSchema>;

type VerifyAccountProps = {
  verifyAccount: (data: VerifyAccountInput) => void;
} & FormComponentProps;

const VerifyAccountSchema = object({
  verificationCode: string().min(1, 'Verification code is required'),
});

export const VerifyAccountForm: React.FC<VerifyAccountProps> = ({
  verifyAccount,
  isError,
  isLoading,
  error,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<VerifyAccountInput>({
    resolver: zodResolver(VerifyAccountSchema),
  });

  const onSubmit = async (data: any) => {
    await verifyAccount(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='w-[72vw] md:w-[32vw] mx-auto'
    >
      {isError ? (
        <div className='my-[1rem] text-sm font-semibold text-red-500 text-center'>{`${
          error.response?.data.errors[0].msg || error.message
        }`}</div>
      ) : null}
      <div>
        <label
          htmlFor='verificationCode'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Verification Code
        </label>

        <input
          {...register('verificationCode')}
          id='verificationCode'
          type='text'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />
        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.verificationCode?.message as string}
        </p>
      </div>

      <div className='mt-8 flex justify-center'>
        <button
          type='submit'
          className='flex items-center justify-center bg-indigo hover:bg-yellow text-sm h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase shadow-all-sides'
        >
          {isLoading && <Spinner size='sm' className='text-white' />}
          <span className='mx-2'>Verify Account</span>
        </button>
      </div>
    </form>
  );
};
