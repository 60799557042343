import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { logo, logoDark, prevLogo } from '@/assets';
import { Head } from '@/components/Head';
import { ErrorProps } from '@/types';
import { notifyError, notifySuccess } from '@/utils/notifications';

import { ForgotPasswordForm } from '../components/ForgotPasswordForm';
import { useForgotPassword } from '../hooks';

export const ForgotPassword = () => {
  const navigate = useNavigate();

  const {
    mutate: forgotPassword,
    isError,
    error,
    isLoading,
    isSuccess,
    reset,
  } = useForgotPassword();

  if (isError) {
    notifyError(
      (error as ErrorProps).response.data.errors[0].msg ||
        (error as ErrorProps).message,
      false,
    );
    reset();
  }

  if (isSuccess) {
    const timeout = 2000;
    notifySuccess('A password reset code has been sent to your email', timeout);
    reset();
    setTimeout(() => navigate('/auth/reset-password'), timeout);
  }
  return (
    <div className='bg-login min-h-[100vh] bg-cream flex flex-col items-center justify-center'>
      <Head title='Forgot Password' />
      <Link to='/'>
        <img src={prevLogo} className='h-8' alt='logo' />
      </Link>

      <h2 className='my-4 font-medium text-indigo'>Forgot Password</h2>
      <div className='shadow-all-sides rounded-lg w-[80vw]  md:w-[40vw] h-[60vh] p-4 flex flex-col items-center'>
        <ForgotPasswordForm
          forgotPassword={forgotPassword}
          isError={isError}
          error={error as ErrorProps}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
      </div>
      <ToastContainer />
    </div>
  );
};
