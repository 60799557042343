import { useNavigate } from 'react-router-dom';

import {
  farmer,
  moneybag,
  house,
  academy,
  bitcoin,
  businesssupport,
} from '@/assets';

interface ItemProps {
  title: string;
  bgIcon: any;
}

const items: ItemProps[] = [
  {
    title: 'Agriculture',
    bgIcon: farmer,
  },
  {
    title: 'Real Estate',
    bgIcon: house,
  },
  {
    title: 'Forex',
    bgIcon: bitcoin,
  },
  {
    title: 'Finacial Services',
    bgIcon: moneybag,
  },
  {
    title: 'Digital Academy',
    bgIcon: academy,
  },
  {
    title: 'Business Coach/Mentorship',
    bgIcon: businesssupport,
  },
];

const Card = ({ title, bgIcon }: ItemProps) => {
  return (
    <div className='shadow-all-sides  h-32 w-40 md:60 lg:w-80 mb-8 flex items-center p-4 rounded-md'>
      <img alt='card-background' src={bgIcon} className='w-20' />
      <span className='-ml-16 text-indigo font-medium'>{title}</span>
    </div>
  );
};

export const WhatWeDo = () => {
  const navigate = useNavigate();

  return (
    <div className='px-8 sm:p-8 mt-8 sm:my-4 mb-8'>
      <h2 className='text-lg font-semibold text-start mb-4 text-transform: uppercase text-[indigo]'>
        Our Business & Services
      </h2>
      <div className=' flex flex-wrap justify-between'>
        {items.map((item) => (
          <Card key={item.title} bgIcon={item.bgIcon} title={item.title} />
        ))}
      </div>

      <div className='flex justify-center'>
        <button
          onClick={() => navigate(`/portal/invest`)}
          type='button'
          className='bg-indigo  mt-8 hover:bg-yellow text-[12px] h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase'
        >
          Start Investing
        </button>
      </div>
    </div>
  );
};
