import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/constants/queryKeys';
import { User } from '@/features/auth';

import { deleteUser, getUsers, suspendUser } from '../api';

export const useGetUsers = () => {
  const usersQuery = useQuery({
    queryKey: [queryKeys.users],
    queryFn: getUsers,
    staleTime: 30 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    select: (data) => {
      // console.log(data.data.resource);

      return data.data?.resource;
    },
  });

  return usersQuery;
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteUser,
    onSuccess: (data: any, _id: string) => {
      queryClient.setQueryData([queryKeys.users], (oldQueryData: any) => {
        console.log({ _id });

        return {
          ...oldQueryData,
          data: {
            ...oldQueryData.data,
            resource: [
              ...oldQueryData.data.resource.filter(
                (item: User) => item._id !== _id,
              ),
            ],
          },
        };
      });
    },
    onError: (error) => {
      console.log({ error });
    },
  });
};

export const useSuspendUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: suspendUser,
    onSuccess: (data: any, _id: string) => {
      queryClient.setQueryData([queryKeys.users], (oldQueryData: any) => {
        const updatedItem = oldQueryData.data.resource.find(
          (item: User) => item._id === _id,
        );

        return {
          ...oldQueryData,
          data: {
            ...oldQueryData.data,
            resource: [
              { ...updatedItem, isSuspended: true },
              ...oldQueryData.data.resource.filter(
                (item: User) => item._id !== _id,
              ),
            ],
          },
        };
      });
    },
    onError: (error) => {
      console.log({ error });
    },
  });
};
