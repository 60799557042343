import React, { useMemo, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { Spinner } from '@/components/Elements';
import { Head } from '@/components/Head';
import { Modal } from '@/components/Modal';
import { Table } from '@/components/Table';
import { formatDate } from '@/utils/functions';
import { notifyError, notifySuccess } from '@/utils/notifications';

import { CashoutForm } from '../components/CashoutForm';
import { TableMenu } from '../components/CashoutMenu';
import { ConfirmCancel } from '../components/ConfirmCancel';
import { useCancelCashout, useGetCashout } from '../hooks';

export const Cashout = () => {
  const { data: cashout, isLoading, isRefetching } = useGetCashout();

  const [isPaidBoxOpen, toggleIsPaidBoxOpen] = useState(false);
  const [isCancelConfirmationOpen, toggleIsCancelConfirmationOpen] =
    useState(false);
  const [selectedCashout, setSelectedCashout] = useState('');

  const {
    mutate: cancelCashout,
    isLoading: isCancelLoading,
    isSuccess: isCancelSuccess,
    isError: isCancelError,
    error: cancelError,
    reset: cancelReset,
  } = useCancelCashout();

  const handlePaid = (_id: string) => {
    setSelectedCashout(_id);
    toggleIsPaidBoxOpen(true);
    //console.log(_id);
  };

  const handleCancel = (_id: string) => {
    //console.log(_id);
    setSelectedCashout(_id);
    toggleIsCancelConfirmationOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'menu',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div className=''>
            <TableMenu
              _id={row.original._id}
              handleCancel={() => handleCancel(row.original._id)}
              handlePaid={() => handlePaid(row.original._id)}
              cashout={row.original}
            />
          </div>
        ),
      },
      {
        Header: 'User',
        Cell: ({ row }: any) => {
          return (
            <span>
              {row.original.user?.firstName} {row.original.user?.lastName}
            </span>
          );
        },
      },
      {
        Header: 'Amount ($)',
        accessor: 'amount',
      },
      {
        Header: 'Is Paid',
        Cell: ({ row }: any) => {
          return (
            <span
              className={
                row.original.isPaid ? 'text-green-500' : 'text-red-500'
              }
            >
              {row.original.isPaid ? 'Yes' : 'No'}
            </span>
          );
        },
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
      {
        Header: 'Network',
        accessor: 'network',
      },
      {
        Header: 'Memo/Tag',
        accessor: 'memoTag',
      },
      {
        Header: 'Is Cancelled',
        Cell: ({ row }: any) => {
          return (
            <span
              className={
                row.original.isCancelled ? 'text-red-500' : 'text-green-500'
              }
            >
              {row.original.isCancelled ? 'Yes' : 'No'}
            </span>
          );
        },
      },
      {
        Header: 'Paid By',
        accessor: 'paidBy',
      },

      {
        Header: 'Payment Proof',
        accessor: 'paymentProof',
      },

      {
        Header: 'Date/ Time',
        Cell: ({ row }: any) => {
          return <span>{formatDate(new Date(row.original.createdAt))}</span>;
        },
        // accessor: 'createdAt',
      },
    ],
    [],
  );

  if (isCancelSuccess) {
    notifySuccess('Cashout request has been cancelled successfully', false);
    cancelReset();
  }

  if (isCancelError) {
    notifyError((cancelError as { message: string }).message as string, false);
    cancelReset();
  }

  return (
    <div>
      <Head title='Cashout' />
      {isLoading || isRefetching ? (
        <div className='flex h-screen justify-center items-center'>
          <Spinner size='md' />
        </div>
      ) : (
        <div className='overflow-hidden '>
          <Table data={cashout ?? []} columns={columns} showPagination={true} />
        </div>
      )}

      {selectedCashout && (
        <Modal
          isOpen={isPaidBoxOpen}
          closeModal={() => toggleIsPaidBoxOpen(false)}
        >
          <CashoutForm
            cashoutID={selectedCashout}
            closeForm={() => toggleIsPaidBoxOpen(false)}
          />
        </Modal>
      )}

      <ConfirmCancel
        isOpen={isCancelConfirmationOpen}
        setIsOpen={toggleIsCancelConfirmationOpen}
        cancelDocument={cancelCashout}
        isLoading={isCancelLoading}
        selectedData={selectedCashout}
      />
      <ToastContainer />
    </div>
  );
};
