import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { TypeOf, object, string } from 'zod';

import { Spinner } from '@/components/Elements';
import { FormComponentProps } from '@/types';

export type ForgotPasswordInput = TypeOf<typeof forgotPasswordSchema>;

type ForgotPasswordProps = {
  forgotPassword: (data: ForgotPasswordInput) => void;
} & FormComponentProps;

const forgotPasswordSchema = object({
  email: string().min(1, 'Email is required'),
});

export const ForgotPasswordForm: React.FC<ForgotPasswordProps> = ({
  forgotPassword,
  isError,
  isLoading,
  error,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ForgotPasswordInput>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  const onSubmit = async (data: ForgotPasswordInput) => {
    await forgotPassword(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='w-[72vw] md:w-[32vw] mx-auto'
    >
      {isError ? (
        <div className='my-[1rem] text-sm font-semibold text-red-500 text-center'>{`${
          error.response?.data.errors[0].msg || error.message
        }`}</div>
      ) : null}
      <div className='mb-4'>
        <label
          htmlFor='email'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Email
        </label>
        <input
          {...register('email')}
          id='email'
          type='text'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />
        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.email?.message as string}
        </p>
      </div>

      <div className='mt-8 flex flex-col items-center justify-center'>
        <button
          type='submit'
          className='flex items-center justify-center bg-indigo hover:bg-yellow text-sm h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase shadow-all-sides'
        >
          {isLoading && <Spinner size='sm' className='text-white' />}
          <span className='mx-2'>Reset Password</span>
        </button>

        <Link
          to='/auth/signin'
          className='font-sm text-sm text-[#0645AD] hover:text-indigo mt-2'
        >
          Sign In
        </Link>
      </div>
    </form>
  );
};
