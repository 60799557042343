import { useMutation, useQuery } from '@tanstack/react-query';

import { applyForLoan, getAllLoans, getUserLoans, repayLoan } from '../api';

export const useGetUserLoans = () => {
  return useQuery({
    queryKey: ['userLoans'],
    queryFn: getUserLoans,
    staleTime: 30 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    select: (data) => {
      return {
        ...data.data,
        resource: data.data.resource
          // .filter((item: any) => item.status === 'RUNNING')
          .map((item: any) => ({
            ...item,
            dueDate: new Date(item.dueDate).toDateString(),
            loanDate: new Date(item.loanDate).toDateString(),
          })),
      }; //.resource;
    },
  });
};

export const useApplyForLoans = () => {
  return useMutation({
    mutationFn: applyForLoan,
  });
};

export const useGetAllLoans = () => {
  return useQuery({
    queryKey: ['loans'],
    queryFn: getAllLoans,
    staleTime: 30 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    select: (data) => {
      return {
        ...data.data,
        resource: data.data.resource
          .filter((item: any) => item.status === 'RUNNING')
          .map((item: any) => ({
            ...item,
            dueDate: new Date(item.dueDate).toDateString(),
            loanDate: new Date(item.loanDate).toDateString(),
          })),
      };
    },
  });
};

export const useRepayLoan = () => {
  return useMutation({
    mutationFn: repayLoan,
  });
};
