import { zodResolver } from '@hookform/resolvers/zod';
import country, { Country } from 'country-list';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { TypeOf, object, string } from 'zod';

import { Spinner } from '@/components/Elements';
import { FormComponentProps } from '@/types';

export type SignUpInput = TypeOf<typeof signUpSchema>;

type SignUpProps = {
  signUp: (data: SignUpInput) => void;
} & FormComponentProps;

const signUpSchema = object({
  firstName: string().min(1, 'First Name is required'),
  lastName: string().min(1, 'Last Name is required'),
  email: string().min(1, 'Email is required'),
  //username: string().min(1, 'Username/ Email is required'),
  password: string().min(1, 'Password is required'),
  confirmPassword: string().min(1, 'Confirm Password is required'),
  phoneNumber: string().min(1, 'Phone Number is required'),
  address: string().min(1, 'Address is required'),
  country: string().min(1, 'Country is required'),
  referredBy: string().optional(),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ['confirmPassword'],
});

export const SignUpForm: React.FC<SignUpProps> = ({
  signUp,
  isError,
  isLoading,
  error,
}) => {
  const { search } = useLocation();
  const referrer = search.split('?referrer=')[1];

  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<SignUpInput>({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      referredBy: referrer,
    },
  });

  const onSubmit = async (data: SignUpInput) => {
    await signUp(data);
  };

  const toggleTermsAcceptance = (val: boolean) => {
    setIsTermsAccepted(val);
  };

  const countryList = country.getData();
  //console.log({ countryList });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='w-[72vw] md:w-[32vw] mx-auto'
    >
      {isError ? (
        <div className='my-[1rem] text-sm font-semibold text-red-500 text-center'>{`${
          error.response?.data.errors[0].msg || error.message
        }`}</div>
      ) : null}
      <div className='mb-4'>
        <label
          htmlFor='firstName'
          className='block text-sm font-medium text-indigo mb-1'
        >
          First Name
        </label>
        <input
          {...register('firstName')}
          id='firstName'
          type='text'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />
        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.firstName?.message as string}
        </p>
      </div>

      <div className='mb-4'>
        <label
          htmlFor='lastName'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Last Name
        </label>
        <input
          {...register('lastName')}
          id='lastName'
          type='text'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />
        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.lastName?.message as string}
        </p>
      </div>

      <div className='mb-4'>
        <label
          htmlFor='email'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Email
        </label>
        <input
          {...register('email')}
          id='email'
          type='text'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />
        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.email?.message as string}
        </p>
      </div>

      {/* <div className='mb-4'>
        <label
          htmlFor='username'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Username
        </label>
        <input
          {...register('username')}
          id='username'
          type='text'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />
        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.username?.message as string}
        </p>
      </div> */}

      <div className='mb-4 hidden'>
        <label
          htmlFor='referredBy'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Referrer
        </label>
        <input
          {...register('referredBy')}
          id='referredBy'
          type='text'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />
        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.referredBy?.message as string}
        </p>
      </div>

      <div className='mb-4'>
        <label
          htmlFor='password'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Password
        </label>

        <input
          {...register('password')}
          id='password'
          type='password'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />
        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.password?.message as string}
        </p>
      </div>

      <div className='mb-4'>
        <label
          htmlFor='confirmPassword'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Confirm Password
        </label>
        <input
          {...register('confirmPassword')}
          id='confirmPassword'
          type='password'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />
        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.confirmPassword?.message as string}
        </p>
      </div>

      <div className='mb-4'>
        <label
          htmlFor='phoneNumber'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Phone Number
        </label>
        <input
          {...register('phoneNumber')}
          id='phoneNumber'
          type='text'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />
        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.phoneNumber?.message as string}
        </p>
      </div>

      <div className='mb-4'>
        <label
          htmlFor='address'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Address
        </label>
        <input
          {...register('address')}
          id='address'
          type='text'
          className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
        />
        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.address?.message as string}
        </p>
      </div>

      <div className='mb-4'>
        <label
          htmlFor='country'
          className='block text-sm font-medium text-indigo mb-1'
        >
          Country
        </label>
        <Controller
          name='country'
          control={control}
          render={({ field: { onChange } }) => {
            return (
              <Select
                closeMenuOnSelect={true}
                classNamePrefix='country'
                className='w-full  text-indigo'
                id='country'
                getOptionLabel={(option: Country) => option.name}
                getOptionValue={(option: Country) => option.code}
                options={countryList}
                onChange={(option: any) => {
                  onChange(option.code);
                }}
                placeholder='Select Country'
              />
            );
          }}
        />
        <p className='mt-1 text-xs text-red-400 font-bold'>
          {errors.country?.message as string}
        </p>
      </div>

      <div className='mb-4 flex items-center'>
        <input
          type='checkbox'
          className='form-checkbox h-3 w-3 text-indigo-600 mr-4 text-indigo bg-transparent rounded-sm'
          checked={isTermsAccepted}
          onChange={(e) => {
            toggleTermsAcceptance(e.target.checked);
          }}
        />

        <span>I accept terms and conditions</span>
      </div>

      <div className='mt-8 flex flex-col justify-center items-center'>
        <button
          type='submit'
          disabled={!isTermsAccepted ? true : false}
          className={`${
            !isTermsAccepted
              ? 'opacity-50 cursor-not-allowed'
              : 'hover:bg-yellow'
          } flex items-center justify-center bg-indigo  text-sm h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase shadow-all-sides`}
        >
          {isLoading && <Spinner size='sm' className='text-white' />}
          <span className='mx-2'>Create Account</span>
        </button>

        {/* <Button isLoading={isLoading} type='submit' className='w-full'>
          Create Account
        </Button> */}

        <Link
          to='/auth/signin'
          className='font-sm text-sm text-[#0645AD] hover:text-indigo mt-2'
        >
          Have an account? Sign In
        </Link>
      </div>
    </form>
  );
};

//export default SignUpForm;
