import { Transition, Menu } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';

import { close, menu, logo, profile } from '@/assets';
import { Link } from '@/components/Elements';
import { navLinks } from '@/constants';
import storage from '@/utils/storage';

type NavbarProps = {
  bgColor: string;
  activePage?: string;
};

export const Navbar = ({ bgColor, activePage }: NavbarProps) => {
  const token = storage.getToken();

  // console.log({ token });

  const [toggle, setToggle] = useState<boolean>(false);

  return (
    <nav className={`w-full flex py-2 px-8 items-center navbar ${bgColor}`}>
      <div className='flex items-center'>
        <Link to='/'>
          <img src={logo} alt='logo' className='main-logo' />
        </Link>
      </div>

      <div className='flex w-full'>
        <ul className='list-none sm:flex hidden justify-center items-center flex-1'>
          {navLinks.map((l, i) => (
            <li
              key={l.id}
              className={`font-poppins font-normal cursor-pointer text-[14px] text-black ${
                activePage === l.id
                  ? 'border-b-[2px] mt-[6px] pb-1 border-yellow text-indigo'
                  : ''
              } ${i === navLinks.length - 1 ? 'mr-0' : 'mr-10'}`}
            >
              <Link
                className='hover:text-yellow text-transform: uppercase'
                to={`${token ? '/app' : ''}/${l.id}`}
              >
                {l.title}
              </Link>
            </li>
          ))}
        </ul>

        {token ? (
          <div className='list-none sm:flex hidden justify-end items-center flex-2'>
            <span
              className={`font-poppins font-normal cursor-pointer text-[14px] text-black mr-10`}
            >
              <Link
                className={`hover:text-white hover:bg-indigo text-transform: uppercase bg-yellow py-1 px-2 rounded-md`}
                to={`/portal/dashboard`}
              >
                Investment
              </Link>
            </span>

            {/* <img
              src={profile}
              className='h-8 w-8 rounded-full'
              alt='user profile pic'
            /> */}
            <Menu as='div' className='relative'>
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className='max-w-xs p-2 flex rounded-full items-center text-sm mr-3'>
                      <span className='sr-only'>Open user menu</span>
                      <div className='profile-bg p-2 rounded-full  border-[1px] border-slate-300'>
                        <UserIcon className='w-[18px] rounded-full text-indigo' />
                      </div>
                      {/* <img
                        src={profile}
                        className='h-8 w-8 rounded-full'
                        alt='user profile pic'
                      /> */}
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items
                      static
                      className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-all-sides py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                    >
                      <Menu.Item key={'logout'}>
                        {() => (
                          <Link
                            to='/'
                            onClick={() => storage.clearToken()}
                            className={'block px-4 py-2 text-sm text-gray-700'}
                          >
                            Logout
                          </Link>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        ) : (
          <ul className='list-none sm:flex hidden justify-end items-center flex-2'>
            <li
              className={`font-poppins font-normal cursor-pointer text-[14px] text-black mr-10`}
            >
              <Link
                className={`hover:text-yellow text-transform: uppercase`}
                to={`/auth/signin`}
              >
                Sign In
              </Link>
            </li>
            <li
              className={`font-poppins font-normal cursor-pointer text-[14px] bg-black text-white py-1 px-2 rounded hover:bg-yellow`}
            >
              <Link
                className={`hover:text-white text-transform: uppercase`}
                to={`/auth/signup`}
              >
                Sign Up
              </Link>
            </li>
          </ul>
        )}
      </div>

      <div className='sm:hidden flex flex-1 justify-end items-center'>
        <button
          className='w-[28px] h-[28px] object-contain'
          onClick={() => setToggle((prev) => !prev)}
        >
          <img src={toggle ? close : menu} alt='menu' />
        </button>

        <div
          className={`${
            toggle ? 'flex' : 'hidden'
          } p-6 bg-slate-100 absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar z-50`}
        >
          <ul className='list-none flex flex-col justify-end items-center flex-1'>
            {navLinks.map((l, i) => (
              <li
                key={l.id}
                className={`font-poppins font-normal cursor-pointer text-[16px] text-black ${
                  activePage === l.id
                    ? 'border-b-[2px] mt-[6px] pb-1 border-yellow text-indigo'
                    : ''
                } ${i === navLinks.length - 1 ? 'mb-0' : 'mb-4'}`}
              >
                <Link
                  onClick={() => {
                    setToggle((prev) => !prev);
                  }}
                  className='hover:text-yellow text-transform: uppercase'
                  to={`${token ? '/app' : ''}/${l.id}`}
                >
                  {l.title}
                </Link>
              </li>
            ))}

            {token ? (
              <li
                className={`font-poppins font-normal cursor-pointer text-[16px] text-black  mt-4`}
              >
                <Link
                  onClick={() => {
                    setToggle((prev) => !prev);
                    storage.clearToken();
                  }}
                  className={`hover:text-yellow text-transform: uppercase `}
                  to={`/`}
                >
                  Sign Out
                </Link>
              </li>
            ) : (
              <>
                <li
                  className={`font-poppins font-normal cursor-pointer text-[16px] text-black mt-4 mb-4`}
                >
                  <Link
                    className={`hover:text-yellow text-transform: uppercase`}
                    to={`/auth/signin`}
                  >
                    Sign In
                  </Link>
                </li>
                <li
                  className={`font-poppins font-normal cursor-pointer text-[16px] text-black`}
                >
                  <Link
                    className={`hover:text-yellow text-transform: uppercase`}
                    to={`/auth/signup`}
                  >
                    Sign Up
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
