import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import storage from '@/utils/storage';

import {
  forgotPassword,
  resetPassword,
  updatePassword,
  login,
  register,
  verifyAccount,
  logout,
  activateAccount,
} from '../api';
import { LoginRespData } from '../types';

export const useForgotPassword = () => {
  return useMutation({
    mutationFn: forgotPassword,
  });
};

export const useResetPassword = () => {
  return useMutation({
    mutationFn: resetPassword,
  });
};

export const useUpdatePassword = () => {
  return useMutation({
    mutationFn: updatePassword,
  });
};

export const useLogin = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: login,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['loginError'] });

      if (data && data.extra.accessToken) {
        const {
          resource,
          extra: { accessToken },
        }: LoginRespData = data;

        storage.setToken(accessToken);
        storage.setUser(resource);

        if (resource.userType === 'ADMIN') {
          navigate('/admin/dashboard');
        } else {
          navigate('/portal/dashboard');
        }
      }
    },
  });
};

export const useRegister = () => {
  return useMutation({
    mutationFn: register,
  });
};

export const useVerifyAccount = () => {
  return useMutation({
    mutationFn: verifyAccount,
  });
};

export const useLogout = () => {
  return useMutation({
    mutationFn: logout,
  });
};

export const useActivateAccount = () => {
  return useMutation({
    mutationFn: activateAccount,
  });
};
