import { Tab } from '@headlessui/react';

import { CourseProps } from '../types';

import { Courses } from './Courses';
import { SearchBar } from './SearchBar';

interface CourseTabsProps {
  allCourses: CourseProps[];
  userCourses: CourseProps[];
}

export const CoursesTabs = ({ allCourses, userCourses }: CourseTabsProps) => {
  return (
    <div className='px-8 mt-8 mb-8 sm:my-4'>
      <Tab.Group>
        <Tab.List className='flex space-x-1 p-1 border-slate-300 border-b-[0px]'>
          <Tab
            key='discover'
            className={({ selected }) => {
              return `${
                selected
                  ? 'border-b-[2px] border-yellow text-indigo'
                  : 'text-slate-400'
              } mr-4`;
            }}
          >
            Discover
          </Tab>

          <Tab
            key='my courses'
            className={({ selected }) => {
              return selected
                ? 'border-b-[2px] border-yellow text-indigo'
                : 'text-slate-400';
            }}
          >
            My Courses
          </Tab>
        </Tab.List>
        <Tab.Panels className='mt-2'>
          <Tab.Panel key={'discovery panel'} className={``}>
            <div className='mb-4'>
              <SearchBar />
            </div>

            <Courses data={allCourses} type='discover' />
          </Tab.Panel>

          <Tab.Panel key={'my courses'} className={``}>
            <div className='mb-4'>
              <SearchBar type={'my_courses'} />
            </div>

            <Courses data={userCourses} type='my_courses' />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
