import { chart } from '@/assets';

export const AboutCompany = () => {
  return (
    <div className='px-8 mt-8 sm:mt-0 mb-8 sm:my-0 sm:p-8 flex'>
      <div className='flex flex-col flex-1'>
        <h2 className='text-lg font-semibold text-start mb-8 text-transform: uppercase text-[indigo]'>
          About the company
        </h2>
        <p className='pr-4'>
          Awap Investors is a global investment platform from Eastern Nigeria
          founded by Okorie Caleb Ezechima in the year 2021 in Ebonyi State,
          Nigeria. Its registered under cooperate affairs commission and
          licensed by Nigerian export promotion council.
          <br />
          <br />
          Awap investors limited is a universal investment platform born out of
          the passion for trade and commerce, minimization of frauds and scams,
          profitizing our social life and mostly using technology to maximize
          our incomes and live our dream life.
        </p>
      </div>
      <div className='hidden sm:flex flex-1 justify-end'>
        <img
          alt='chart'
          src={chart}
          className='mt-16 md:mt-0 h-[350px] md:h-[450px]'
        />
      </div>
    </div>
  );
};
