import { course } from '@/assets';
import { Head } from '@/components/Head';
import storage from '@/utils/storage';

import { Courses } from '../components/Courses';
import { CoursesTabs } from '../components/CoursesTabs';
import { Header } from '../components/Header';
import { SearchBar } from '../components/SearchBar';
import { CourseProps } from '../types';

export const sampleCourses: CourseProps[] = [
  {
    title: 'JavaScript Beginers Guide',
    ratings: 3.5,
    duration: '3 Months',
    original_price: 60.0,
    discounted_price: 15.75,
    thumbnail: course,
    progress: 100,
    purchased: true,
  },
  {
    title: 'JavaScript Beginers Guide',
    ratings: 4.5,
    duration: '12 Weeks',
    original_price: 16.75,
    discounted_price: 6.75,
    thumbnail: course,
  },
  {
    title: 'JavaScript Beginers Guide',
    ratings: 4.5,
    duration: '12 Weeks',
    original_price: 16.75,
    discounted_price: 6.75,
    thumbnail: course,
  },
  {
    title: 'JavaScript Beginers Guide',
    ratings: 4.5,
    duration: '12 Weeks',
    original_price: 16.75,
    discounted_price: 6.75,
    thumbnail: course,
    progress: 100,
    purchased: true,
  },

  {
    title: 'JavaScript Beginers Guide',
    ratings: 3.5,
    duration: '3 Months',
    original_price: 60.0,
    discounted_price: 15.75,
    thumbnail: course,
    progress: 0,
    purchased: true,
  },
  {
    title: 'JavaScript Beginers Guide',
    ratings: 4.5,
    duration: '12 Weeks',
    original_price: 16.75,
    discounted_price: 6.75,
    thumbnail: course,
  },
  {
    title: 'JavaScript Beginers Guide',
    ratings: 4.5,
    duration: '12 Weeks',
    original_price: 16.75,
    discounted_price: 6.75,
    thumbnail: course,
  },
  {
    title: 'JavaScript Beginers Guide',
    ratings: 4.5,
    duration: '12 Weeks',
    original_price: 16.75,
    discounted_price: 6.75,
    thumbnail: course,
    progress: 0,
    purchased: true,
  },

  {
    title: 'JavaScript Beginers Guide',
    ratings: 4.5,
    duration: '12 Weeks',
    original_price: 16.75,
    discounted_price: 6.75,
    thumbnail: course,
    progress: 20,
    purchased: true,
  },
  {
    title: 'JavaScript Beginers Guide',
    ratings: 4.5,
    duration: '12 Weeks',
    original_price: 16.75,
    discounted_price: 6.75,
    thumbnail: course,
    progress: 20,
    purchased: true,
  },
  {
    title: 'JavaScript Beginers Guide',
    ratings: 4.5,
    duration: '12 Weeks',
    original_price: 16.75,
    discounted_price: 6.75,
    thumbnail: course,
    progress: 20,
    purchased: true,
  },
  {
    title: 'JavaScript Beginers Guide',
    ratings: 4.5,
    duration: '12 Weeks',
    original_price: 16.75,
    discounted_price: 6.75,
    thumbnail: course,
    progress: 20,
    purchased: true,
  },
  {
    title: 'JavaScript Beginers Guide',
    ratings: 4.5,
    duration: '12 Weeks',
    original_price: 16.75,
    discounted_price: 6.75,
    thumbnail: course,
    progress: 20,
    purchased: true,
  },
];

export const Academy = () => {
  const isAuthenticated = storage.getToken() ? true : false;
  return (
    <div>
      <Head title='Academy' />
      <h1 className='my-4 text-2xl text-center text-indigo'>
        AWAP DIGITAL ACADEMY (ADA) COMING SOON!!!
      </h1>
      {isAuthenticated ? (
        <>
          <CoursesTabs
            allCourses={sampleCourses}
            userCourses={sampleCourses.filter(
              (course) => course.purchased === true,
            )}
          />
        </>
      ) : (
        <>
          <Header />
          <div className='px-8 mt-8 sm:mt-0 mb-8 sm:my-0'>
            <SearchBar />
          </div>

          <div className='px-8 mt-8 sm:mt-0 mb-8 sm:my-0 sm:p-8'>
            <Courses data={sampleCourses} />
          </div>
        </>
      )}
    </div>
  );
};
