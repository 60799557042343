import { axios } from '@/lib/axios';
import storage from '@/utils/storage';

import { WalletInput } from '../components/AddWalletForm';
import { CashoutInput } from '../components/CashoutForm';
import { ConvertInput } from '../components/ConvertForm';
import { DepositInput } from '../components/DepositForm';
import { TransferInput } from '../components/TransferForm';

export const getUserWallet = async () => {
  const userId = storage.getUser()._id;
  const resp = await axios.get(`/wallets/user-wallet/${userId}`);
  return resp.data;
};

export const getUserTransacactions = async () => {
  const resp = await axios.get('/transactions/my-transactions/all');
  return resp.data;
};

export const deposit = async (payload: DepositInput) => {
  const resp = await axios.post('/wallets/transaction/deposit', {
    ...payload,
    mode: 'CRYPTO',
  });

  return resp.data;
};

export const transferFunds = async (payload: TransferInput) => {
  const resp = await axios.post('/wallets/transaction/transfer', payload);

  return resp.data;
};

export const convertFunds = async (payload: ConvertInput) => {
  const resp = await axios.post('/wallets/transaction/convert', payload);

  return resp.data;
};

export const cashout = async (payload: CashoutInput) => {
  const resp = await axios.post('/wallets/transaction/cashout', payload);

  return resp.data;
};

export const addWallet = async (payload: WalletInput) => {
  const resp = await axios.post('/wallets/add-cashout-wallet', payload);

  return resp.data;
};

export const deleteWallet = async (address: string) => {
  const resp = await axios.delete(`/wallets/${address}`);

  return resp.data;
};

export const getUserByWalletId = async (walletId: string) => {
  const resp = await axios.get(`/wallets/user/${walletId}`);
  return resp.data;
};

export const getAllWallets = async () => {
  const resp = await axios.get(`/wallets?pageSize=5000`);
  return resp.data;
};
