import { useNavigate } from 'react-router-dom';

import { computerlogin, finance, telecommuting } from '@/assets';

const Card = ({
  title,
  content,
  img,
}: {
  title: string;
  content: string;
  img: any;
}) => {
  return (
    <div className='bg-white px-2 my-2 sm:my-0 shadow-all-sides w-full sm:w-[28vw] h-[40vh] justify-center flex-col flex items-center rounded-md'>
      <h3 className='font-semibold text-sm'>{title}</h3>
      <span className='text-[12px] text-center py-2'>{content}</span>
      <img alt='computer-login' src={img} className='h-[20vh]' />
    </div>
  );
};

export const Guide = () => {
  const navigate = useNavigate();

  return (
    <div className='px-8 mt-8 sm:my-0 sm:p-8'>
      <h2 className='text-lg font-semibold text-center mb-8'>
        Start investing using these simple steps
      </h2>

      <div className='flex flex-col sm:flex-row justify-between'>
        <Card
          title='Create Account'
          content='Register either directly or through a sponsors link.'
          img={computerlogin}
        />

        <Card
          title='Activate Account'
          content='Activate your account with a one time payment.'
          img={telecommuting}
        />

        <Card
          title='Start Investing'
          content='Choose any investment package of your choice and start investing.'
          img={finance}
        />
      </div>

      <div className='flex justify-center'>
        <button
          onClick={() => navigate(`/portal/invest`)}
          type='button'
          className='bg-indigo  mt-8 hover:bg-yellow text-[12px] h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase'
        >
          Start Investing
        </button>
      </div>
    </div>
  );
};
