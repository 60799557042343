import React from 'react';

import { cert1, cert2, cert3 } from '@/assets';

const CERTIFICATES = [
  {
    alt: 'Certificate 2',
    src: cert2,
  },
  {
    alt: 'Certificate 1',
    src: cert1,
  },
  {
    alt: 'Certificate 3',
    src: cert3,
  },
];

const Certificates = () => {
  return (
    <div className='justify-between md:flex'>
      {CERTIFICATES.map((cert) => (
        <div key={cert.alt} className='mb-4'>
          <img className='md:w-[350px]' alt={cert.alt} src={cert.src} />
        </div>
      ))}
    </div>
  );
};

export const Certification = () => {
  return (
    <div className='px-8 mt-8 mb-8 sm:p-8 sm:my-4'>
      <h2 className='text-lg font-semibold text-start mb-4 text-transform: uppercase text-[indigo]'>
        Certifications
      </h2>
      <Certificates />
    </div>
  );
};
