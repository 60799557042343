import { Hero } from '@/components/Layout/Hero';

export const AboutHero = () => {
  return (
    <Hero
      bgColor='bg-blue'
      h1Content='About US'
      pContent='Welcome to Awap Investors, where your financial aspirations are our driving force. We are more than just an investment company; we are your trusted partners on the journey to financial success. Our commitment is to provide you with a range of investment opportunities and comprehensive support services that empowers you to achieve your financial goals.'
      page='about'
      buttonBg='bg-yellow'
    />
  );
};
