/* eslint-disable @typescript-eslint/no-unused-vars */
import { Head } from '@/components/Head';

import { AboutCompany } from '../components/AboutCompany';
import { AboutHero } from '../components/AboutHero';
import { BoardDirectors } from '../components/BoardDirectors';
import { Certification } from '../components/Certification';
import { Visionary } from '../components/Visionary';
import { WhatWeDo } from '../components/WhatWeDo';

export const About = () => {
  return (
    <div className='min-h-[100vh]'>
      <Head title='About' />
      <AboutHero />
      <AboutCompany />
      <Visionary />
      <BoardDirectors />
      <WhatWeDo />
      <Certification />
    </div>
  );
};
