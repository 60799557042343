import { Dialog, Transition } from '@headlessui/react';
import { PlayIcon } from '@heroicons/react/24/solid';
import { Fragment, useState } from 'react';

import { halfstar, line, filledstar, productimg } from '@/assets';
import CheckoutForm from '@/components/Forms/CheckForm';

export const CourseHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className='p-4 my-8 sm:m-8 bg-[#F5F5F6] min-h-[300px] rounded-md flex flex-col sm:flex-row'>
        <div
          className='flex flex-[0.4] min-h-[30vh] sm:min-h-[0vh] flex-col justify-center bg-cover bg-center items-center'
          style={{
            backgroundImage:
              'url(https://image.tmdb.org/t/p/original/iuFNMS8U5cb6xfzi51Dbkovj7vM.jpg)',
          }}
        >
          <div className='w-full h-full min-h-[30vh] sm:min-h-[0vh] backdrop-brightness-50'>
            <div className='h-full w-full min-h-[30vh] sm:min-h-[0vh] flex flex-col justify-center items-center'>
              <a href='/#'>
                <div className='text-white hover:text-primary flex justify-center items-center h-12 w-12 transparent-bg p-2 rounded-full hover:bg-slate-200 '>
                  <PlayIcon className='h-6 w-6 ' />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className='flex flex-col flex-[0.6] ml-0 sm:ml-8 my-4 sm:my-0 justify-between'>
          <h3 className='text-lg font-medium text-indigo mb-6'>
            JavaScript Beginers Guide
          </h3>
          <p className='text-sm mb-4 text-indigo'>
            Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt
            qui esse pariatur duis deserunt mollit dolore cillum minim,
          </p>
          <div className='flex items-center my-2'>
            <span className='text-[#DD6A57] text-sm mr-2 font-medium'>4.5</span>
            <img className='h-4 mr-2' src={filledstar} alt='filledstar icon' />
            <img className='h-4 mr-2' src={filledstar} alt='filledstar icon' />
            <img className='h-4 mr-2' src={filledstar} alt='star icon' />
            <img className='h-4 mr-2' src={filledstar} alt='filledstar icon' />
            <img className='h-4 mr-2' src={halfstar} alt='filledstar icon' />
            <span className='text-sm text-indigo'>(52)</span>
          </div>

          <div className='flex items-center'>
            <span className='text-[#A6A6A6] text-sm'>Course Duration: </span>
            <span className='ml-2  text-sm text-indigo'>4 Weeks</span>
            <img alt='line' src={line} className='mx-2' />
            <span className='text-[#A6A6A6] text-sm'>Number of Lessons:</span>
            <span className='ml-2  text-sm text-indigo'>12</span>
          </div>

          <div className='flex'>
            <span className='text-md text-indigo mr-2 mb-4'>$6.75</span>
            <span className='text-[#A6A6A6] text-decoration-line: line-through text-md'>
              $16.75
            </span>
          </div>

          <div className='flex'>
            <button
              type='button'
              onClick={openModal}
              className='bg-indigo border-[#52516B] border-[1px] hover:bg-yellow hover:text-white text-[12px] h-[40px] w-[200px] p-2 rounded-md text-white text-tranform: uppercase'
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <div className='relative'>
                  <button
                    onClick={closeModal}
                    className='shadow-all-sides absolute -top-4 -right-4 z-50 bg-cream p-2 rounded-full text-gray-500 hover:text-gray-700 cursor-pointer'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-5 w-5'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>

                  <Dialog.Panel className='checkout-bg flex flex-col sm:flex-row min-h-[60vh] sm:[w-70vw] md:w-[60vw] transform overflow-hidden rounded-2xl bg-cream p-6 text-left align-middle shadow-all-sides transition-all'>
                    <>
                      <div className='flex flex-col flex-[0.4]'>
                        <img
                          src={productimg}
                          alt='product'
                          className='h-[180px]'
                        />

                        <h3 className='font-medium text-indigo my-2'>
                          JavaScript Beginers Guide
                        </h3>

                        <p className='text-xs mb-2 text-[#455A64]'>
                          Appendectomy; when done for indicated purpose at time
                          of other major procedure (not as separate procedure)
                          (List separately in addition to code for primary
                          procedure)
                        </p>

                        <div className='flex sm:flex-col justify-between'>
                          <div className='my-0 sm:my-2'>
                            <span className='text-sm text-indigo'>Total: </span>
                            <span className='text-sm text-indigo font-medium ml-0 sm:ml-1'>
                              $6.99
                            </span>
                          </div>

                          <div className=''>
                            <span className='text-sm text-indigo'>Name: </span>
                            <span className='text-sm text-indigo font-medium ml-0 sm:ml-1'>
                              Robert Fox
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-col flex-[0.6] mt-4 sm:mt-0 sm:ml-4'>
                        <h3 className='text-md text-indigo font-medium text-transform: uppercase mb-4'>
                          Checkout
                        </h3>

                        <CheckoutForm closeForm={closeModal} />
                      </div>
                    </>
                  </Dialog.Panel>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
