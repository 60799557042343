import { axios } from '@/lib/axios';

import { CashoutInput } from '../components/CashoutForm';

export const getTransactions = async () => {
  const resp = await axios.get(`/transactions?pageSize=5000`);
  return resp.data;
};

export const getCashout = async () => {
  const resp = await axios.get(`/wallets/cashout/all`);
  return resp.data;
};

export const paidCashout = async ({
  cashoutID,
  payload,
}: {
  cashoutID: string;
  payload: CashoutInput;
}) => {
  const resp = await axios.patch(`/wallets/cashout/${cashoutID}/paid`, payload);
  return resp.data;
};

export const cancelCashout = async (_id: string) => {
  const resp = await axios.patch(`/wallets/cashout/${_id}/cancel`, {
    cancelationReason: 'not eligible',
  });
  return resp.data;
};
