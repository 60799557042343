/* eslint-disable @typescript-eslint/no-unused-vars */
import { Head } from '../Head';

import { Footer } from './Footer';
import { Navbar } from './Navbar';

type MainLayoutProps = {
  children: React.ReactNode;
  page: string;
};

export const MainLayout = ({ children, page }: MainLayoutProps) => {
  return (
    <>
      <Head />
      <Navbar
        activePage={page}
        bgColor={page === 'about' ? 'bg-blue' : 'bg-cream'}
      />
      <div>{children}</div>
      <Footer />
    </>
  );
};
