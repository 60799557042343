import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { logo } from '@/assets';
import { Head } from '@/components/Head';
import { ErrorProps } from '@/types';
import { notifyError, notifySuccess } from '@/utils/notifications';

import { ResetPasswordForm } from '../components/ResetPasswordForm';
import { useResetPassword } from '../hooks';

export const ResetPassword = () => {
  const navigate = useNavigate();

  const {
    mutate: resetPassword,
    isError,
    error,
    isLoading,
    isSuccess,
    reset,
  } = useResetPassword();

  if (isError) {
    notifyError(
      (error as ErrorProps).response.data.errors[0].msg ||
        (error as ErrorProps).message,
      false,
    );
    reset();
  }

  if (isSuccess) {
    const timeout = 2000;
    notifySuccess('Password reset successful.', timeout);
    reset();
    setTimeout(() => navigate('/auth/signin'), timeout);
  }

  return (
    <div className='bg-login min-h-[100vh] bg-cream flex flex-col items-center justify-center'>
      <Head title='Reset Password' />
      <Link to='/'>
        <img src={logo} className='h-8' alt='logo' />
      </Link>

      <h2 className='my-4 font-medium text-indigo'>Reset your password</h2>
      <div className='shadow-all-sides rounded-lg w-[80vw]  md:w-[40vw] h-[60vh] p-4 flex flex-col items-center'>
        <ResetPasswordForm
          resetPassword={resetPassword}
          isError={isError}
          error={error as ErrorProps}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
      </div>
      <ToastContainer />
    </div>
  );
};
