export const navLinks = [
  {
    id: 'about',
    title: 'About',
    path: '/about',
  },
  {
    id: 'academy',
    title: 'Academy',
    path: '/academy',
  },
  {
    id: 'mentorship',
    title: 'Mentorship',
    path: '/mentorship',
  },

  {
    id: 'buycrypto',
    title: 'Buy & Sell Crypto',
    path: '/buy-crypto',
  },
];
