import { axios } from '@/lib/axios';

export const getUsers = async () => {
  const resp = await axios.get(`/users?pageSize=5000`);
  return resp.data;
};

export const deleteUser = async (_id: string) => {
  const resp = await axios.delete(`/users/${_id}`);
  return resp.data;
};

export const suspendUser = async (_id: string) => {
  const resp = await axios.patch(
    `/users/${_id}/suspend`,
    //  {
    //   isSuspended: true,
    // }
  );
  return resp.data;
};
