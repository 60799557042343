type SearchBarProps = {
  type?: 'discover' | 'my_courses';
};

export const SearchBar = ({ type }: SearchBarProps) => {
  return (
    <div className=''>
      <div className='flex items-center justify-center'>
        <input
          placeholder='Search'
          className=' my-4 bg-[#F1F1F1] border-[1px] border-[#A6A6A6] focus:bg-white  p-2 w-[60vw] rounded-md text-center'
        />
      </div>

      {type === 'my_courses' ? (
        <div className='flex'>
          <button className='mr-4 bg-indigo border-[#52516B] border-[1px] mt-2 hover:bg-transparent hover:text-indigo text-[12px] h-[40px] w-[200px] p-2 rounded-md text-white'>
            Active Courses
          </button>

          <button className='bg-transparent border-[#52516B] border-[1px] mt-2 hover:bg-indigo hover:text-white text-[12px] h-[40px] w-[200px] p-2 rounded-md text-indigo'>
            Completed Courses
          </button>
        </div>
      ) : (
        <div className='flex flex-wrap '>
          <button className='bg-indigo border-[#52516B] border-[1px] mt-2 hover:bg-transparent hover:text-indigo text-[12px] h-[40px] sm:w-[200px] mr-2 p-2 rounded-md text-white'>
            All Courses
          </button>

          <button className='bg-transparent border-[#52516B] border-[1px] mt-2 hover:bg-indigo hover:text-white text-[12px] h-[40px] sm:w-[200px] mr-2 p-2 rounded-md text-indigo'>
            Graphic design
          </button>

          <button className='bg-transparent border-[#52516B] border-[1px] mt-2 hover:bg-indigo hover:text-white text-[12px] h-[40px] sm:w-[200px] mr-2 p-2 rounded-md text-indigo'>
            Product Design
          </button>

          <button className='bg-transparent border-[#52516B] border-[1px] mt-2 hover:bg-indigo hover:text-white text-[12px] h-[40px] sm:w-[200px] mr-2 p-2 rounded-md text-indigo'>
            Frontend Development
          </button>

          <button className='bg-transparent border-[#52516B] border-[1px] mt-2 hover:bg-indigo hover:text-white text-[12px] h-[40px] sm:w-[200px] mr-2 p-2 rounded-md text-indigo'>
            Digital Marketing
          </button>

          <button className='bg-transparent border-[#52516B] border-[1px] mt-2 hover:bg-indigo hover:text-white text-[12px] h-[40px] sm:w-[200px] mr-2 p-2 rounded-md text-indigo'>
            Backend Development
          </button>

          <button className='bg-transparent border-[#52516B] border-[1px] mt-2 hover:bg-indigo hover:text-white text-[12px] h-[40px] sm:w-[200px] mr-2 p-2 rounded-md text-indigo'>
            DevOps Engineering
          </button>
        </div>
      )}
    </div>
  );
};
