import { Route, Routes, useLocation } from 'react-router-dom';

import { MainLayout } from '@/components/Layout';
import { About } from '@/features/about';
import { Academy, CourseDetails } from '@/features/academy';
import { AuthRoutes } from '@/features/auth';
import { BuyCrypto } from '@/features/buycrypto';
import { Mentorship } from '@/features/mentorship';
import { PrivacyPolicy, TermsCondition } from '@/features/misc';

const UnAuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path='about' element={<About />} />
      <Route path='academy' element={<Academy />} />
      <Route path='academy/:course' element={<CourseDetails />} />
      <Route path='buycrypto' element={<BuyCrypto />} />
      <Route path='mentorship' element={<Mentorship />} />
      <Route path='privacy-policy' element={<PrivacyPolicy />} />
      <Route path='terms' element={<TermsCondition />} />
    </Routes>
  );
};

const DynamicMainLayout = () => {
  const location = useLocation();
  const page: string = location.pathname.split('/')[1];

  return (
    <MainLayout page={page}>
      <UnAuthenticatedRoutes />
    </MainLayout>
  );
};

export const publicRoutes = [
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
  {
    path: '/*',
    element: <DynamicMainLayout />,
  },
];
