/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useRowSelectColumn } from '@lineup-lite/hooks';
import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useTable, useRowSelect, Column, Row } from 'react-table';

type DataProps = readonly object[];

export type TableRow = {
  Row: Row;
};

type TableProps = {
  data: DataProps;
  columns: any; //readonly Column<object>[];
  showPagination: boolean;
};

type EventProps = {
  selected: number;
};

const Previous = () => {
  return (
    <div className='flex items-center'>
      <ChevronLeftIcon className='h-[18px] w-[18px]' />
    </div>
  );
};

const Next = () => {
  return (
    <>
      <ChevronRightIcon className='h-[18px] w-[18px]' />
    </>
  );
};

export const Table = ({ columns, data, showPagination }: TableProps) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const endOffset = itemOffset + itemsPerPage;
  const [currentItems, setCurrentItems] = useState<DataProps>([]);

  useEffect(() => {
    setCurrentItems(data.slice(itemOffset, endOffset));
  }, [itemOffset, data, endOffset]);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: currentItems,
      },
      // useRowSelect,
      // useRowSelectColumn
    );

  const handlePageClick = (event: EventProps) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className='flex flex-col mt-2'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <div className='shadow overflow-hidden min-h-[20vh] mb-4'>
              <table
                {...getTableProps()}
                className='min-w-full divide-y-[1px] divide-slate-200 text-slate-700 sm:rounded-lg'
              >
                <thead className='bg-cream'>
                  {headerGroups.map((headerGroup) => (
                    <tr className='' {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps()}
                          className='px-2 py-2 text-left text-[14px] font-bold text-slate-600 rounded-sm'
                        >
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className='bg-white divide-y-[1px] divide-slate-200 text-[14px]'
                >
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr id='table-row' {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className='px-2 py-2 whitespace-nowrap text-slate-500'
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showPagination && (
        <div className='flex flex-col items-center justify-center my-4 md:flex-row md:my-0'>
          <h3 className='text-[14px] text-slate-500'>
            Showing {data.length ? itemOffset + 1 : 0} to{' '}
            {data.length < itemsPerPage ? data.length : endOffset} of{' '}
            {data.length} items
          </h3>
          <ReactPaginate
            activeClassName='active'
            breakLabel='...'
            nextLabel={<Next />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel={<Previous />}
            renderOnZeroPageCount={null}
            className='mx-10 rounded-md py-2 my-2 pagination flex items-center justify-center text-slate-500'
          />
          <div className='flex items-center text-slate-500 mb-4 md:mb-0'>
            <select
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              className='mr-2 border-[1px] border-slate-200 h-[38px] w-[80px] rounded-md text-slate-500'
              name='itemsPerPaage'
              id='itemsPerPaage'
              value={itemsPerPage}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={500}>500</option>
              <option value={1000}>1000</option>
            </select>
            records
          </div>
        </div>
      )}
    </>
  );
};
