import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  XMarkIcon,
  Bars3BottomLeftIcon as MenuAltIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { logoLight } from '@/assets';
import { Link } from '@/components/Elements';
import { adminNavigation } from '@/constants';
import storage from '@/utils/storage';

import { Head } from '../Head';

const convertToTitleCase = (text: string) => {
  const arr: string[] = text.split('');
  const first = arr.shift() as string;
  return first.toUpperCase() + arr.join('');
};

const formatTitle = (text: string) => {
  if (text.includes('-')) {
    const arr = text.split('-');
    return convertToTitleCase(arr[0]) + ' ' + convertToTitleCase(arr[1]);
  }
  return convertToTitleCase(text);
};

type PortalLayoutProps = {
  children: React.ReactNode;
};

type SidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type UserNavigationItem = {
  name: string;
  to: string;
  onClick?: () => void;
};

const Logo = () => (
  <div className='flex items-center'>
    <Link to='/'>
      <img src={logoLight} alt='logo' className='w-32' />
    </Link>
  </div>
);

const SideNavigation: React.FC<{ closeNav?: () => void }> = ({ closeNav }) => {
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    const path_index = adminNavigation.findIndex((nav) => nav.path === path);

    setActiveNav(path_index);
  }, [path]);

  const [activeNav, setActiveNav] = useState(0);

  return (
    <>
      {adminNavigation.map((item, index) => (
        <div
          key={item.title}
          className={`${
            index === activeNav ? 'bg-[#170E88]' : ''
          } mx-2 ps-6 py-3 hover:bg-[#170E88]`}
        >
          <NavLink
            end={index === activeNav}
            to={item.path}
            className={clsx('flex items-center', 'text-[15px]', 'text-white')}
            onClick={() => {
              setActiveNav(index);
              closeNav && closeNav();
            }}
          >
            <img
              src={item.icon}
              alt='icon'
              className={clsx('mr-2 flex-shrink-0 w-4')}
              // aria-hidden='true'
            />

            {item.title}
          </NavLink>
        </div>
      ))}
    </>
  );
};

const Sidebar = () => (
  <div className='hidden md:flex md:flex-shrink-0 bg-indigo w-[22%] rounded-lg'>
    <div className='flex flex-col w-[100%]'>
      <div className='flex flex-col flex-1 h-0'>
        <div className='flex items-center h-20 flex-shrink-0 px-4  header-box-shadow border-b-[0.9px] border-[#5A59A4]'>
          <Logo />
        </div>
        <div className='flex flex-col flex-1 overflow-y-auto'>
          <nav className='flex-1 py-2 mt-2 space-y-1 bg-dimWhite-100'>
            <SideNavigation />
          </nav>
        </div>
      </div>
    </div>
  </div>
);

const MobileSidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => (
  <Transition.Root show={sidebarOpen} as={Fragment}>
    <Dialog
      as='div'
      static
      className='fixed inset-0 z-40 flex md:hidden'
      open={sidebarOpen}
      onClose={setSidebarOpen}
    >
      <Transition.Child
        as={Fragment}
        enter='transition-opacity ease-linear duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity ease-linear duration-300'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
      </Transition.Child>
      <Transition.Child
        as={React.Fragment}
        enter='transition ease-in-out duration-300 transform'
        enterFrom='-translate-x-full'
        enterTo='translate-x-0'
        leave='transition ease-in-out duration-300 transform'
        leaveFrom='translate-x-0'
        leaveTo='-translate-x-full'
      >
        <div className='relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-indigo'>
          <Transition.Child
            as={React.Fragment}
            enter='ease-in-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in-out duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='absolute top-0 right-0 pt-2 -mr-12'>
              <button
                className='flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                onClick={() => setSidebarOpen(false)}
              >
                <span className='sr-only'>Close side navigation</span>
                <XMarkIcon className='w-6 h-6 text-white' aria-hidden='true' />
              </button>
            </div>
          </Transition.Child>
          <div className='flex items-center flex-shrink-0 px-4'>
            <Logo />
          </div>
          <div className='flex-1 h-0 mt-5 overflow-y-auto'>
            <nav className='px-2 space-y-1'>
              <SideNavigation closeNav={() => setSidebarOpen(false)} />
            </nav>
          </div>
        </div>
      </Transition.Child>
      <div className='flex-shrink-0 w-14' aria-hidden='true'></div>
    </Dialog>
  </Transition.Root>
);

const Header = () => {
  const location = useLocation();
  const path_name = location.pathname.split('/admin/')[1];

  const loggedInUser = storage.getUser();

  const userNavigation = [
    { name: loggedInUser.email, to: '#' },
    {
      name: 'Change Password',
      to: '/auth/change-password',
    },
    {
      name: 'Sign out',
      to: '/',
      onClick: () => {
        storage.clearToken();
      },
    },
  ].filter(Boolean) as UserNavigationItem[];

  return (
    <div className='flex items-center justify-between w-full py-2 mt-4 md:mt-0'>
      {path_name && (
        <h2 className='flex mr-2 font-medium sm:hidden text-md sm:text-lg text-indigo'>
          {path_name === 'dashboard'
            ? `Hello!! ${loggedInUser.firstName},`
            : formatTitle(path_name)}
        </h2>
      )}

      {path_name && (
        <h2 className='hidden mr-2 font-medium sm:flex text-md sm:text-lg text-indigo'>
          {path_name === 'dashboard'
            ? `Hello!! ${loggedInUser.firstName},`
            : formatTitle(path_name)}
        </h2>
      )}

      <div className='flex items-center justify-end min-w-[150px] sm:min-w-[300px]'>
        <div className='items-center hidden sm:flex'>
          <Menu as='div' className='relative'>
            {({ open }) => (
              <>
                <div>
                  <Menu.Button className='flex items-center max-w-xs text-sm rounded-full'>
                    <span className='sr-only'>Open user menu</span>
                    <div className='profile-bg rounded-full border-[2px] p-2'>
                      <UserIcon className='w-[18px] rounded-full text-indigo' />
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items
                    static
                    className='absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md md:w-56 shadow-all-sides ring-1 ring-black ring-opacity-5 focus:outline-none'
                  >
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <Link
                            onClick={item.onClick}
                            to={item.to}
                            className={clsx(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700',
                            )}
                          >
                            {item.name}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export const AdminPortalLayout = ({ children }: PortalLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const loggedInUser = storage.getUser();
  const userNavigation = [
    { name: loggedInUser.email, to: '#' },
    {
      name: 'Change Password',
      to: '/auth/change-password',
    },
    {
      name: 'Sign out',
      to: '/auth/signin',
      onClick: () => {
        storage.clearToken();
      },
    },
  ].filter(Boolean) as UserNavigationItem[];
  return (
    <div className='flex h-screen p-3 overflow-hidden md:bg-slate-200'>
      <Head />
      <MobileSidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <Sidebar />
      <div className='flex flex-col flex-1 w-0 overflow-hidden bg-white rounded-lg md:ml-4'>
        <div className='relative z-10 flex flex-shrink-0 min-h-16 hr-shadow'>
          <button
            className='px-4 text-white border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden'
            onClick={() => setSidebarOpen(true)}
          >
            <span className='sr-only'>Open side navigation</span>
            <MenuAltIcon
              color='#040259'
              className='w-6 h-6 '
              aria-hidden='true'
            />
          </button>
          <div className='flex items-center justify-between w-full ml-2 md:hidden'>
            <Logo />

            <div className='flex mr-4 sm:hidden'>
              <Menu as='div' className='relative'>
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button className='flex items-center max-w-xs text-sm rounded-full'>
                        <span className='sr-only'>Open user menu</span>
                        <div className='profile-bg p-1 rounded-full  border-[1px] border-slate-300'>
                          <UserIcon className='w-[18px] rounded-full text-indigo' />
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Menu.Items
                        static
                        className='absolute right-0 py-1 mt-2 origin-top-right bg-white rounded-md w-52 shadow-all-sides ring-1 ring-black ring-opacity-5 focus:outline-none'
                      >
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link
                                onClick={item.onClick}
                                to={item.to}
                                className={clsx(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700',
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>

          <div className='flex-1 hidden px-4 my-4 md:flex'>
            <Header />
          </div>
        </div>

        <main className='relative flex-1 px-4 pb-4 overflow-auto focus:outline-none md:py-4'>
          <div className='flex md:hidden'>
            <Header />
          </div>
          {children}
        </main>
      </div>
    </div>
  );
};
