import { dislike, filledstar, like, profile, chevrondown } from '@/assets';

const Review = () => {
  return (
    <div className='flex min-h-[32vh] min-w-[42vw]  mb-1 sm:mb-6'>
      <div className='flex flex-[0.2] justify-end'>
        <img
          alt='student profile'
          src={profile}
          className='h-14 w-14 rounded-full'
        />
      </div>
      <div className='flex flex-[0.8] flex-col ml-4'>
        <div className='flex justify-between'>
          <h3>Cody Fisher</h3>

          <div className='flex flex-col  items-end mb-2'>
            <div className='flex my-2'>
              <img
                className='h-4 mr-2'
                src={filledstar}
                alt='filledstar icon'
              />
              <img
                className='h-4 mr-2'
                src={filledstar}
                alt='filledstar icon'
              />
              <img
                className='h-4 mr-2'
                src={filledstar}
                alt='filledstar icon'
              />
              <img
                className='h-4 mr-2'
                src={filledstar}
                alt='filledstar icon'
              />
              <img
                className='h-4 mr-2'
                src={filledstar}
                alt='filledstar icon'
              />
            </div>
            <span className='text-[#A6A6A6] text-sm'>1 Week Ago</span>
          </div>
        </div>
        <p className='text-sm text-indigo'>
          I had I was very sad this day. There were friendly people at the bar
          that engaged with me. Interactions with people was very well needed. I
          enjoyed a great Long Island ice tea,some tasty vegetarian nachos, and
          sat by the water
        </p>
        <div className='my-2'>
          <span className='text-sm text-indigo'>Was this helpful?</span>
        </div>
        <div className='flex '>
          <button className='mr-4'>
            <img src={like} alt='like' className='h-4 w-4' />
          </button>
          <button>
            <img src={dislike} alt='dislike' className='h-4 w-4' />
          </button>
        </div>
      </div>
    </div>
  );
};

export const CourseReviews = () => {
  return (
    <div className=''>
      <h3 className='text-lg font-medium text-indigo mb-6 mt-6 sm:mt-0'>
        Course Reviews
      </h3>

      <div className='grid justify-between grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 grid-template-columns: 1fr 1fr gap-4'>
        <Review />
        <Review />
        <Review />
        <Review />

        <button className='flex ml-16 items-center'>
          <span className='text-sm text-yellow font-medium mr-1'>
            Show More
          </span>
          <img src={chevrondown} alt='chevron down' className='h-4 w-4' />
        </button>
      </div>
    </div>
  );
};
