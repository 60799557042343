/* eslint-disable no-restricted-imports */
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { TypeOf, object, string } from 'zod';

import { Spinner } from '@/components/Elements';
import { useGetWallet } from '@/features/wallet/hooks';
import { ErrorProps } from '@/types';
import { notifyError, notifySuccess } from '@/utils/notifications';

import { usePaidCashout, useGetCashout } from '../hooks';

export type CashoutInput = TypeOf<typeof cashoutSchema>;

type CashoutProps = {
  closeForm: () => void;
  cashoutID: string;
};

const cashoutSchema = object({
  paymentProof: string().min(1, 'Payment Proof is Required'),
});

export const CashoutForm: React.FC<CashoutProps> = ({
  cashoutID,
  closeForm,
}) => {
  const { refetch: refetchWallet } = useGetWallet();
  const { refetch: refetchCashout } = useGetCashout();
  const {
    mutate: handlePaidCashout,
    isLoading,
    isError,
    error,
    isSuccess,
    reset,
    data,
  } = usePaidCashout();

  if (isError) {
    notifyError(
      (error as ErrorProps).response.data.errors[0].msg ||
        (error as ErrorProps).message,
      3000,
    );
    reset();
  }

  if (isSuccess) {
    refetchWallet();
    refetchCashout();
    notifySuccess(data.data.msg, 3000);
    reset();
    closeForm();
  }

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<CashoutInput>({
    resolver: zodResolver(cashoutSchema),
  });

  const onSubmit = async (data: CashoutInput) => {
    await handlePaidCashout({ cashoutID, payload: data });

    //closeForm();
  };

  return (
    <div className='flex flex-col w-full'>
      <h3 className='text-md text-indigo font-medium text-transform: uppercase mb-4 text-center'>
        Update Cashout
      </h3>

      <span className='text-sm text-indigo mb-4 text-center'>
        Mark Cashout as Paid
      </span>

      <form onSubmit={handleSubmit(onSubmit)} className=''>
        <div className='mb-4'>
          <label
            htmlFor='paymentProof'
            className='block text-sm font-medium text-indigo'
          >
            Payment Proof
          </label>
          <input
            {...register('paymentProof')}
            type='text'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.paymentProof?.message as string}
          </p>
        </div>
        <div className='mt-16 flex justify-center'>
          <button
            type='submit'
            className='flex items-center justify-center bg-indigo hover:bg-yellow text-sm h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase shadow-all-sides'
          >
            {isLoading && <Spinner size='sm' className='text-white' />}
            <span className='mx-2'>Save</span>
          </button>
        </div>
      </form>
    </div>
  );
};
