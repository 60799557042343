import { axios } from '@/lib/axios';
import storage from '@/utils/storage';

import { EnrolInput } from '../components/EnrolmentForm';

export const enrol = async (payload: EnrolInput) => {
  const resp = await axios.post('/mentorships', payload);

  return resp.data;
};
