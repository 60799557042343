import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { TypeOf, object, string } from 'zod';

import { Spinner } from '@/components/Elements';
import { ErrorProps } from '@/types';
import { notifyError, notifySuccess } from '@/utils/notifications';

import { useBuyCrypto } from '../hooks';

export type BuyCryptoInput = TypeOf<typeof buyCryptoSchema>;

type FormProps = {
  setAmount: any;
  selectedCoin: any;
  closeForm: any;
  openPaymentModal: any;
};

const buyCryptoSchema = object({
  amount: string().min(1, 'Amount is required'),
  email: string().min(1, 'Email is required'),
  coinType: string().min(1, 'Coin is Required'),
  walletAddress: string().min(1, 'Wallet Address is required'),
  network: string().min(1, 'Network is required'),
  memo: string().optional(),
});

export const BuyCryptoForm: React.FC<FormProps> = ({
  selectedCoin,
  setAmount,
  closeForm,
  openPaymentModal,
}) => {
  const { mutate, isError, isLoading, isSuccess, error, data, reset } =
    useBuyCrypto();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<BuyCryptoInput>({
    resolver: zodResolver(buyCryptoSchema),
    defaultValues: { coinType: selectedCoin?.coinShortName },
  });

  const onSubmit = (data: BuyCryptoInput) => {
    setAmount(data.amount), mutate(data);
  };

  if (isSuccess) {
    notifySuccess(data.data.msg, 3000);

    closeForm();
    reset();
    openPaymentModal();
  }

  if (isError) {
    notifyError(
      (error as ErrorProps).response.data.errors[0].msg ||
        (error as ErrorProps).message,
      2000,
    );
    reset();
  }

  return (
    <div className='flex flex-col w-full'>
      <h3 className='text-md text-indigo font-medium text-transform: uppercase mb-4 text-center'>
        Buy Crypto
      </h3>

      <span className='text-sm text-indigo mb-4 text-center'>
        Buy Crypto from Awap Investments LTD
      </span>

      <form onSubmit={handleSubmit(onSubmit)} className=''>
        <div className='mb-4'>
          <label
            htmlFor='email'
            className='block text-sm font-medium text-indigo'
          >
            Email Address
          </label>
          <input
            {...register('email')}
            type='email'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.email?.message as string}
          </p>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='amount'
            className='block text-sm font-medium text-indigo'
          >
            Amount ($)
          </label>
          <input
            {...register('amount')}
            type='number'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.amount?.message as string}
          </p>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='coinType'
            className='block text-sm font-medium text-indigo'
          >
            Coin
          </label>
          <input
            {...register('coinType')}
            type='text'
            readOnly={true}
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.coinType?.message as string}
          </p>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='walletAddress'
            className='block text-sm font-medium text-indigo'
          >
            Wallet Address
          </label>
          <input
            {...register('walletAddress')}
            type='text'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.walletAddress?.message as string}
          </p>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='network'
            className='block text-sm font-medium text-indigo'
          >
            Network
          </label>
          <input
            {...register('network')}
            type='text'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.network?.message as string}
          </p>
        </div>
        <div className='mb-4'>
          <label
            htmlFor='memo'
            className='block text-sm font-medium text-indigo'
          >
            Memo/Tag
          </label>
          <input
            {...register('memo')}
            type='text'
            className='mt-1 p-2 w-full border-none rounded-md shadow-all-sides'
          />
          <p className='mt-1 text-xs text-red-400 font-bold'>
            {errors.memo?.message as string}
          </p>
        </div>

        <div className='mt-16 flex justify-center'>
          <button
            type='submit'
            className='flex items-center justify-center bg-indigo hover:bg-yellow text-sm h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase shadow-all-sides'
          >
            {isLoading && <Spinner size='sm' className='text-white' />}
            <span className='mx-2'>Proceed</span>
          </button>
        </div>
        {/* )} */}
      </form>
    </div>
  );
};
