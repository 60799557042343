import { halfstar, filledstar } from '@/assets';
import { HorizontalBarRating } from '@/components/Rating';

export const StudentFeedback = () => {
  return (
    <div className=''>
      <h3 className='text-lg font-medium text-indigo mb-6'>Student Feedback</h3>
      <div className='flex min-h-[20vh]'>
        <div className='flex flex-col sm:flex-row flex-1 sm:flex-[0.7]'>
          <div className='flex flex-col flex-[0.2] mr-4'>
            <span className='text-[#DD6A57] text-6xl mb-2 font-medium'>
              4.5
            </span>
            <div className='flex my-2'>
              <img
                className='h-4 mr-2'
                src={filledstar}
                alt='filledstar icon'
              />
              <img
                className='h-4 mr-2'
                src={filledstar}
                alt='filledstar icon'
              />
              <img
                className='h-4 mr-2'
                src={filledstar}
                alt='filledstar icon'
              />
              <img
                className='h-4 mr-2'
                src={filledstar}
                alt='filledstar icon'
              />
              <img className='h-4 mr-2' src={halfstar} alt='filledstar icon' />
            </div>

            <span className='text-sm text-indigo'>Course Rating</span>
          </div>
          <div className='flex flex-col flex-[0.8]'>
            <HorizontalBarRating rating={4.4} />
            <HorizontalBarRating rating={0.35} />
            <HorizontalBarRating rating={0.2} />
            <HorizontalBarRating rating={0.1} />
            <HorizontalBarRating rating={0.05} />
          </div>
        </div>
      </div>
    </div>
  );
};
