import { supportPerson, wrench } from '@/assets';

export const Header = () => {
  return (
    <div className='h-[25vh] md:h-[30vh] bg-cream flex px-8'>
      <div className='flex flex-[0.5] justify-center mr- items-end'>
        <img
          src={supportPerson}
          alt='support'
          className='h-[120px] sm:h-[180px]'
        />
      </div>
      <div className='flex flex-[0.5] bg-wrench items-center sm:p-16'>
        <h1 className='text-2xl md:p-16 sm:text-3xl md:text-4xl -ml-16  text-center font-semibold text-indigo'>
          Business Coach & Mentorship
        </h1>
        <img className='h-[40px] sm:h-[60px] -mt-12' alt='' src={wrench} />
      </div>
    </div>
  );
};
