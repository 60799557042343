import { Hero } from '@/components/Layout/Hero';

export const LandingHero = () => {
  return (
    <Hero
      bgColor='bg-cream'
      h1Content='Embracing financial possibilities!'
      pContent='We provide the technologies and strategies that helps you secure your future by embracing financial possibilities through investment, savings, loans, crypto exchange, digital shares & skills.'
      page='landing'
      buttonBg='bg-indigo'
    />
  );
};
