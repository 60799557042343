import { useEffect, useState } from 'react';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';

import { Spinner } from '@/components/Elements';
import { Head } from '@/components/Head';
import { Modal } from '@/components/Modal';

import { Banner } from '../components/Banner';
import { BuyCryptoForm } from '../components/BuyCryptoForm';
import { SellCryptoForm } from '../components/SellCryptoForm';
import { useGetCompanyConfig, useGetCryptoWallets } from '../hooks';

export type WalletAddress = {
  network: string;
  wallet: string;
  _id: string;
};

export type CryptoWallet = {
  coinName: string;
  address: WalletAddress[];
  coinShortName: string;
  memo: string;
  tag: string;
  _id: string;
};

const actionOptions = [
  {
    value: 'buy',
    label: 'Buy',
  },
  {
    value: 'sell',
    label: 'Sell',
  },
];
const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    overflowY: 'auto',
    maxHeight: '300px', // Adjust based on your needs
  }),
};

const Payment = ({ amount, paymentDetails, handleBtnClick, buyPrice }: any) => {
  return (
    <div>
      <h2 className='text-2xl mb-4'>
        Pay ₦{(parseInt(amount) * parseInt(buyPrice)).toLocaleString()} into the
        bank account below:
      </h2>
      <h4 className='text-md mb-2'>Bank Name: {paymentDetails.name}</h4>
      <h4 className='text-md mb-2'>
        Account Number: {paymentDetails.accountNumber}
      </h4>
      <h4 className='text-md mb-2'>
        Account Name: {paymentDetails.accountName}
      </h4>
      <button
        onClick={handleBtnClick}
        className='flex items-center justify-center bg-indigo hover:bg-yellow text-sm h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase shadow-all-sides'
      >
        I have paid the money
      </button>
    </div>
  );
};

const CryptoPayment = ({
  amount,
  handleBtnClick,
  selectedCoin,
  selectedAddress,
}: any) => {
  console.log({ amount, selectedCoin, selectedAddress });
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(selectedAddress.wallet);
    setCopied(true);

    setTimeout(() => setCopied(false), 5000);
  };

  return (
    <div>
      <h2 className='text-2xl mb-4'>
        Transfer ${amount} worth of {selectedCoin.coinShortName} into the crypto
        wallet below:
      </h2>
      <h4 className='text-md mb-2'>Coin Name: {selectedCoin.coinShortName}</h4>
      <h4 className='text-md mb-2'>Network: {selectedAddress.network}</h4>
      <h4 className='text-md mb-2'>Wallet Address: {selectedAddress.wallet}</h4>
      <h4 className='text-md mb-2'>MEMO: {selectedCoin?.memo}</h4>
      <h4 className='text-md mb-2'>TAG: {selectedCoin?.tag}</h4>
      <button
        onClick={copyToClipboard}
        className={`h-[40px] w-[200px] p-2 mb-4 rounded border ${
          copied
            ? 'text-green-500 border-green-500'
            : 'border-indigo text-indigo'
        } `}
      >
        {copied ? 'Copied!' : 'Copy Wallet Address'}
      </button>
      <button
        onClick={handleBtnClick}
        className='flex items-center justify-center bg-indigo hover:bg-yellow text-sm h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase shadow-all-sides'
      >
        I transfered the crypto
      </button>
    </div>
  );
};

export const BuyCrypto = () => {
  const [action, setAction] = useState('buy');
  const [isBuyFormOpen, setBuyFormOpen] = useState(false);
  const [isSellFormOpen, setSellFormOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isCryptoPaymentModalOpen, setIsCryptoPaymentModalOpen] =
    useState(false);

  const [selectedCoin, setSelectedCoin] = useState<CryptoWallet | null>(null);
  const [selectedCoinBuyPrice, setSelectedCoinBuyPrice] = useState<
    number | null
  >(null);
  const [selectedCoinSellPrice, setSelectedCoinSellPrice] = useState<
    number | null
  >(null);
  const [amount, setAmount] = useState();
  const [whatsAppLink, setWhatsAppLink] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');

  const { data: cryptoWallets, isFetching: isFetchingWallets } =
    useGetCryptoWallets();

  const { data: companyConfig, isFetching: isFetchingCompanyConfig } =
    useGetCompanyConfig();

  useEffect(() => {
    const defaultCoin =
      cryptoWallets && cryptoWallets.length ? cryptoWallets[0] : '';

    const coin = selectedCoin ?? defaultCoin;

    if (coin) {
      setSelectedCoin(coin);
    }

    if (cryptoWallets && companyConfig) {
      if (coin && companyConfig) {
        const buyPrice = companyConfig.cryptoConfig?.find(
          (crypto: any) => crypto.coinShortName === coin.coinShortName,
        )?.buyPrice[0]?.amount;

        const sellPrice = companyConfig.cryptoConfig?.find(
          (crypto: any) => crypto.coinShortName === coin.coinShortName,
        )?.sellPrice[0]?.amount;

        if (buyPrice) {
          setSelectedCoinBuyPrice(buyPrice);
        }

        if (sellPrice) {
          setSelectedCoinSellPrice(sellPrice);
        }
      }

      setWhatsAppLink(companyConfig?.whatsAppLink);
    }
  }, [cryptoWallets, companyConfig, selectedCoin]);

  const handlePaymentConfirmation = () => {
    window.open(whatsAppLink, '_blank');
  };

  return (
    <div className='px-8 mt-8 sm:mt-0 mb-8 sm:my-0 sm:p-8'>
      <Head title='Buy & Sell Crypto' />
      <Banner />
      {isFetchingWallets || isFetchingCompanyConfig ? (
        <div className=' flex flex-col justify-center items-center'>
          <Spinner size='lg' />
        </div>
      ) : (
        <div>
          <div className=' flex flex-col justify-center items-center'>
            <div className='flex justify-between w-[300px] sm:w-[600px] '>
              <div className='sm:w-[220px]'>
                <Select
                  className='w-full  text-indigo'
                  id='option'
                  isSearchable={false}
                  defaultValue={actionOptions.find(
                    (option) => option.value === action,
                  )}
                  options={actionOptions}
                  onChange={(option: any) => {
                    setAction(option.value);
                  }}
                />
              </div>
              <div className='sm:w-[220px] '>
                <Select
                  classNamePrefix='w-full'
                  id='coin-select'
                  isSearchable={false}
                  defaultValue={selectedCoin}
                  getOptionLabel={(option: any) => option?.coinName}
                  getOptionValue={(option: any) => option?.address[0].wallet}
                  options={
                    cryptoWallets && cryptoWallets.length ? cryptoWallets : []
                  }
                  onChange={(option: any) => {
                    setSelectedCoin(option);
                  }}
                  styles={customStyles}
                />
              </div>
            </div>
          </div>

          <div className='my-8 max-w-[540px] mx-auto'>
            <div className='h-[180px] mb-2 sm:w-[540px] flex flex-col shadow-all-sides rounded-md'>
              <div className='p-2 border-b-[1px] flex flex-[0.1] justify-center text-indigo text-md font-semibold'>
                AWAP Rates ({selectedCoin?.coinShortName})
              </div>
              <div className='flex flex-col justify-between flex-[0.9]'>
                <div className='flex flex-col flex-1 p-2 items-center justify-center'>
                  <span className='text-indigo text-sm font-medium'>
                    {action === 'buy' ? 'Buy' : 'Sell'}{' '}
                    {selectedCoin?.coinShortName}
                  </span>
                  <span className='text-indigo text-3xl font-semibold'>
                    $1 = ₦
                    {action === 'buy'
                      ? (selectedCoinBuyPrice as number)?.toLocaleString()
                      : action === 'sell'
                      ? (selectedCoinSellPrice as number)?.toLocaleString()
                      : ''}
                  </span>
                </div>

                <button
                  onClick={() => {
                    if (action === 'buy') {
                      setBuyFormOpen(true);
                    }
                    if (action === 'sell') {
                      setSellFormOpen(true);
                    }
                  }}
                  type='submit'
                  className={`flex items-center justify-center ${
                    action === 'buy'
                      ? 'bg-yellow hover:bg-indigo'
                      : 'bg-indigo hover:bg-yellow'
                  } text-sm h-[40px] w-full p-2 rounded-md text-white text-transform: uppercase shadow-all-sides`}
                >
                  {action === 'buy' ? 'Buy' : 'Sell'}
                </button>
              </div>
            </div>
          </div>
          <Modal
            isOpen={isBuyFormOpen}
            closeModal={() => setBuyFormOpen(false)}
          >
            <BuyCryptoForm
              setAmount={setAmount}
              selectedCoin={selectedCoin as CryptoWallet}
              closeForm={() => setBuyFormOpen(false)}
              openPaymentModal={() => setIsPaymentModalOpen(true)}
            />
          </Modal>

          <Modal
            isOpen={isPaymentModalOpen}
            closeModal={() => setIsPaymentModalOpen(false)}
          >
            <Payment
              amount={amount}
              paymentDetails={companyConfig?.companyAccountDetails}
              handleBtnClick={handlePaymentConfirmation}
              whatsappLink={companyConfig?.whatsAppLink}
              buyPrice={selectedCoinBuyPrice}
            />
          </Modal>

          <Modal
            isOpen={isSellFormOpen}
            closeModal={() => setSellFormOpen(false)}
          >
            <SellCryptoForm
              setAmount={setAmount}
              selectedCoin={selectedCoin}
              closeForm={() => setSellFormOpen(false)}
              setSelectedAddress={setSelectedAddress}
              openPaymentModal={() => setIsCryptoPaymentModalOpen(true)}
            />
          </Modal>

          <Modal
            isOpen={isCryptoPaymentModalOpen}
            closeModal={() => setIsCryptoPaymentModalOpen(false)}
          >
            <CryptoPayment
              amount={amount}
              handleBtnClick={handlePaymentConfirmation}
              selectedCoin={selectedCoin as CryptoWallet}
              selectedAddress={selectedAddress}
            />
          </Modal>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};
