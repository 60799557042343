import { books } from '@/assets';

export const Header = () => {
  return (
    <div className='px-8 mt-8 sm:mt-8 mb-8 mx-8 sm:my-0 sm:p-8 flex min-h-[15vh] md:min-h-[20vh] bg-blue justify-between items-center rounded-md'>
      <div className='flex flex-[0.5] sm:flex-[0.2]'>
        <h2 className='text-lg font-semibold text-start text-transform: uppercase text-[indigo]'>
          Awap Academy
        </h2>
      </div>

      <div className='sm:flex flex-[0.4] hidden'>
        <p className=''>
          Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui
          esse pariatur duis deserunt mollit dolore cillum minim tempor enim.
        </p>
      </div>

      <div className='flex flex-[0.5] sm:flex-[0.2]'>
        <img className='-mt-[3vh] md:-mt-[6vh]' src={books} alt='books stack' />
      </div>
    </div>
  );
};
