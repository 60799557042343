/* eslint-disable no-restricted-imports */
import { User } from '@/features/auth';
import { Loan } from '@/features/loans/types';
import { Wallet } from '@/features/wallet/types';
import { SavingPlan } from '@/types';

export const getLineGraphData = (labels: any, data: any) => {
  return {
    labels,
    datasets: [
      {
        data,
        fill: false,
        backgroundColor: 'rgba(8, 91, 197, 0.6)',
        borderColor: 'rgba(8, 91, 197, 0.6)',
        borderWidth: 2,
      },
    ],
  };
};

export const filterTransactions = (filterBy: string, transactions: any) => {
  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const oneDayAgo = new Date(currentDate);
  oneDayAgo.setDate(oneDayAgo.getDate() - 1);

  let filteredData;

  switch (filterBy) {
    case 'monthly':
      filteredData = transactions.filter(
        (trans: any) =>
          new Date(trans.createdAt).getMonth() === new Date().getMonth(),
      );
      break;
    case 'weekly':
      filteredData = transactions.filter(
        (trans: any) =>
          new Date(trans.createdAt) >= oneWeekAgo &&
          new Date(trans.createdAt) <= currentDate,
      );
      break;
    case 'today':
      filteredData = transactions.filter(
        (trans: any) =>
          new Date(trans.createdAt) >= oneDayAgo &&
          new Date(trans.createdAt) <= currentDate,
      );
      break;
    default:
      return transactions;
  }
  return filteredData;
};

const getPortfolioLineGraphData = (performance: number[], m_o_m: string) => {
  const color = m_o_m.includes('-') ? '#EB5757' : '#00D467';

  return {
    labels: [
      'Label1',
      'Label2',
      'Label3',
      'Label4',
      'Label5',
      'Label6',
      'Label7',
      'Label8',
      'Label9',
      'Label10',
      'Label11',
      'Label12',
      'Label13',
    ],
    datasets: [
      {
        label: '',
        data: performance,
        fill: false,
        backgroundColor: color, //'rgba(8, 91, 197, 0.6)',
        borderColor: color, //'rgba(8, 91, 197, 0.6)',
        borderWidth: 2,
      },
    ],
  };
};

export const getPortfolioTableData = (
  portfolioSum: any,
  investmentsExtras: any,
) => {
  const agriculture = investmentsExtras
    ? investmentsExtras.agriculture.map((item: any) => item.amount)
    : [];

  const realEstate = investmentsExtras
    ? investmentsExtras.realEstate.map((item: any) => item.amount)
    : [];

  const forex = investmentsExtras
    ? investmentsExtras.forex.map((item: any) => item.amount)
    : [];

  return portfolioSum
    ? [
        {
          title: 'Agriculture',
          current_value:
            portfolioSum
              .find((item: any) => item._id === 'AGRICULTURE')
              ?.totalAmount.toLocaleString() || 0,
          m_o_m: '-4.07%',
          review_date: 'Dec 30, 2019 07:52',
          performance: getPortfolioLineGraphData(agriculture, '+4%'),
        },
        {
          title: 'Real Estate',
          current_value:
            portfolioSum
              .find((item: any) => item._id === 'REAL_ESTATE')
              ?.totalAmount.toLocaleString() || 0,
          m_o_m: '+4.07%',
          review_date: 'Dec 30, 2019 07:52',
          performance: getPortfolioLineGraphData(realEstate, '+4.07%'),
        },
        {
          title: 'Forex',
          current_value:
            portfolioSum
              .find((item: any) => item._id === 'FOREX')
              ?.totalAmount.toLocaleString() || 0,
          m_o_m: '+4.07%',
          review_date: 'Dec 30, 2019 07:52',
          performance: getPortfolioLineGraphData(forex, '+4.07%'),
        },
      ]
    : [];
};

export const calculateSavingsTarget = (savingsPlan: SavingPlan): number => {
  const { startingAmount, duration } = savingsPlan;

  const startDate = new Date(savingsPlan.createdAt);
  const endDate = new Date(duration);
  const timeDifference = endDate.getTime() - startDate.getTime();

  const numberOfIntervals: number = Math.floor(timeDifference / 1);

  // Calculate the savings target
  const savingsTarget = startingAmount * numberOfIntervals;

  return parseFloat(savingsTarget.toFixed(2));
};

export const calculateSavingsSum = (savings: SavingPlan[]): number => {
  if (savings.length) {
    const sum = savings.reduce((total, plan) => total + plan.currentAmount, 0);

    return sum;
  }
  return 0;
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const populateUsersWithWalletsAndLoans = (
  users: User[],
  wallets: Wallet[],
  loans: Loan[],
) => {
  if (users?.length >= 0 && wallets?.length >= 0 && loans?.length > 0) {
    console.log(loans, users, wallets);
    const populatedData = users.map((user) => ({
      ...user,
      wallet: wallets.find((wallet) => wallet.user?._id === user._id),
      loans: loans.find((loan) => loan.user?._id === user._id),
    }));

    return populatedData;
  } else {
    return [];
  }
};

export const formatDate = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const month = date.getMonth() + 1; // Month starts from 0
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedHours = hours % 12 || 12; // Convert to 12-hour format
  const ampm = hours >= 12 ? 'PM' : 'AM';

  return `${day}/${month}/${year} ${formattedHours}:${
    minutes < 10 ? '0' : ''
  }${minutes}${ampm}`;
};
