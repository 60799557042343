import React from 'react';

export const TermsCondition = () => {
  return (
    <div className='max-w-4xl mx-auto min-h-[60vh] py-4 text-indigo px-8'>
      <h3 className='text-center font-semibold text-2xl py-4'>
        Terms and Conditions
      </h3>

      <h4 className='font-medium py-4'>1. General Terms</h4>
      <p className='font-light mb-4'>
        1.1. These Terms and Conditions (&ldquo;Terms&ldquo;) govern the use of
        our online global platform (&ldquo;AWAP INVESTORS LTD&ldquo;).
      </p>

      <p className='font-light mb-4'>
        1.2. By using the Platform, you agree to be bound by these Terms.
      </p>

      <p className='font-light mb-4'>
        1.3. We reserve the right to modify these Terms at any time without
        notice.
      </p>

      <p className='font-light mb-4'>
        1.4. The Platform is operated by [AWAP INVESTORS LTD], a company
        registered in Nigeria.
      </p>

      <p className='font-light mb-4'>
        1.5. These Terms constitute a legally binding agreement between you and
        us.
      </p>

      <h4 className='font-medium py-4'>2. Definitions</h4>
      <p className='font-light mb-4'>
        2.1. &ldquo;User&ldquo; or &ldquo;You&ldquo; means any person using the
        Platform.
      </p>

      <p className='font-light mb-4'>
        2.2. &ldquo;We&ldquo; or &ldquo;Us&ldquo; means AWAP INVESTORS LTD, the
        operator of the Platform.
      </p>

      <p className='font-light mb-4'>
        2.3. &ldquo;Account&ldquo; means a user&lsquo;s registered account on
        the Platform.
      </p>

      <p className='font-light mb-4'>
        2.4. &ldquo;Investment&ldquo; means any financial instrument or asset
        available for investment on the Platform.
      </p>

      <p className='font-light mb-4'>
        2.5. &ldquo;Loan&ldquo; means any loan or credit facility provided
        through the Platform.
      </p>

      <p className='font-light mb-4'>
        2.6. &ldquo;Savings&ldquo; means any savings account or product offered
        through the Platform.
      </p>

      <p className='font-light mb-4'>
        2.7. &ldquo;Mentorship Class&ldquo; means any online business mentorship
        class or program offered through the Platform.
      </p>

      <p className='font-light mb-4'>
        2.8. &ldquo;Crypto&ldquo; means any cryptocurrency or digital asset
        available for buying or selling on the Platform.
      </p>

      <h4 className='font-medium py-4'>3. Account Registration</h4>
      <p className='font-light mb-4'>
        3.1. To use the Platform, you must register for an Account.
      </p>

      <p className='font-light mb-4'>
        3.2. You must provide accurate and complete information during
        registration.
      </p>

      <p className='font-light mb-4'>
        3.3. You are responsible for maintaining the security of your Account.
      </p>

      <p className='font-light mb-4'>
        3.4. You must add ur legal informations that you match with your KYC
        verification.
      </p>

      <h4 className='font-medium py-4'>4. Investment Terms</h4>
      <p className='font-light mb-4'>
        4.1. All Investments are subject to market risks and may result to
        profit appreciation or depreciation.
      </p>

      <p className='font-light mb-4'>
        4.2. There is no refund of capital after investment rather you wait for
        ROI
      </p>

      <p className='font-light mb-4'>
        4.3. You are solely responsible for your investment decisions.
      </p>

      <p className='font-light mb-4'>
        4.4. We may charge fees and commissions for transactions on the
        Platform.
      </p>

      <p className='font-light mb-4'>
        4.5. Users must conduct their own research and due diligence before an
        investment.
      </p>

      <h4 className='font-medium py-4'>5.Loan Terms </h4>
      <p className='font-light mb-4'>
        5.1. Loans are subject to credit approval and may require collateral.
      </p>

      <p className='font-light mb-4'>
        5.2. You must repay the Loan with interest and fees as agreed.
      </p>

      <p className='font-light mb-4'>
        5.3. Late payments may incur additional fees and penalties.
      </p>

      <h4 className='font-medium py-4'>6. Savings Terms</h4>
      <p className='font-light mb-4'>
        6.1 Savings are held in a separate Account.
      </p>

      <p className='font-light mb-4'>
        6.2 Savings earn interest at a rate determined by the Platform depending
        on the plan.
      </p>

      <p className='font-light mb-4'>
        6.3 Users can withdraw Savings at any time depending on the plan.
      </p>

      <h4 className='font-medium py-4'>7. Digital Shares Terms</h4>
      <p className='font-light mb-4'>
        7.1 Digital Shares represent ownership in a company or entity.
      </p>

      <p className='font-light mb-4'>
        7.2 Users must conduct their own research and due diligence before
        purchasing Digital Shares.
      </p>

      <p className='font-light mb-4'>
        7.3 Digital Shares are subject to market fluctuations and may lose value
        or add value.
      </p>

      <p className='font-light mb-4'>
        7.4 Digital shares are frozen for 6 months after purchase.
      </p>

      <h4 className='font-medium py-4'>8. Mentorship Class Terms</h4>
      <p className='font-light mb-4'>
        8.1. Mentorship Classes are subject to availability and schedules.
      </p>

      <p className='font-light mb-4'>
        8.2. You must register in advance for Mentorship Classes.
      </p>

      <p className='font-light mb-4'>
        8.3. Mentorship Classes are for educational purposes only and do not
        constitute our investment platform.
      </p>

      <h4 className='font-medium py-4'>9. Crypto Terms</h4>
      <p className='font-light mb-4'>
        9.1. Crypto transactions are subject to market risks and may result in
        losses.
      </p>

      <p className='font-light mb-4'>
        9.2. We do not provide Crypto investment advice or recommendations.
      </p>

      <p className='font-light mb-4'>
        9.3. You are solely responsible for your Crypto transactions.
      </p>

      <p className='font-light mb-4'>
        9.4. Crypto transactions are made at your own risk and discretion.
      </p>

      <p className='font-light mb-4'>
        9.5. Users must conduct their own research and due diligence before
        using the platform.
      </p>

      <p className='font-light mb-4'>
        9.6. We only pay when we receive your coin.
      </p>

      <p className='font-light mb-4'>
        9.7. Send your cryptos to the right network, lost of funds is at your
        own risk.
      </p>

      <h4 className='font-medium py-4'>10. Fees and Charges</h4>
      <p className='font-light mb-4'>
        10.1 The Platform charges fees for certain services.
      </p>

      <p className='font-light mb-4'>
        10.2 Fees are deducted from the user&lsquo;s Account.
      </p>

      <p className='font-light mb-4'>
        10.3 Users are responsible for paying any applicable taxes.
      </p>

      <p className='font-light mb-4'>
        10.4 Fees and charges are subject to change without notice.
      </p>

      <h4 className='font-medium py-4'>11. Payment Terms</h4>
      <p className='font-light mb-4'>
        11.1. All payments must be made through approved payment methods.
      </p>

      <p className='font-light mb-4'>
        11.2. You are responsible for any payment processing fees.
      </p>

      <p className='font-light mb-4'>
        11.3. payments into the wrong wallet is at users risk.
      </p>

      <h4 className='font-medium py-4'>12. Intellectual Property</h4>
      <p className='font-light mb-4'>
        12.1. The Platform and its content are our exclusive property.
      </p>

      <p className='font-light mb-4'>
        12.2. You may not reproduce, modify, or distribute our intellectual
        property.
      </p>

      <p className='font-light mb-4'>
        12.3. the platform owns all intellectual property rights.
      </p>

      <h4 className='font-medium py-4'>13. User Conduct</h4>
      <p className='font-light mb-4'>
        13.1. You must use the Platform in good faith and for its intended
        purpose.
      </p>

      <p className='font-light mb-4'>
        13.2. You may not engage in illegal or fraudulent activities on the
        Platform.
      </p>

      <p className='font-light mb-4'>
        13.3. You may not infringe on the rights of others or violate applicable
        laws.
      </p>

      <h4 className='font-medium py-4'>14. Data Privacy</h4>
      <p className='font-light mb-4'>
        14.1. We collect and process your personal data in accordance with our
        Privacy Policy.
      </p>

      <p className='font-light mb-4'>
        14.2. You consent to the collection and processing of your personal
        data.
      </p>

      <h4 className='font-medium py-4'>15. Disclaimer of Warranties</h4>
      <p className='font-light mb-4'>
        15.1. The Platform and its content are provided on an &ldquo;as
        is&ldquo; and &ldquo;as available&ldquo; basis.
      </p>

      <p className='font-light mb-4'>
        15.2. We disclaim all warranties, express or implied.
      </p>

      <h4 className='font-medium py-4'>16. Limitation of Liability</h4>
      <p className='font-light mb-4'>
        16.1 The Platform shall not be liable for any damages or losses.
      </p>

      <p className='font-light mb-4'>
        16.2 The Platform&lsquo;s liability is limited to the amount of fees
        paid by the user.
      </p>

      <h4 className='font-medium py-4'>17. Indemnification</h4>
      <p className='font-light mb-4'>
        17.1 Users shall indemnify the Platform for any damages or losses.
      </p>

      <p className='font-light mb-4'>
        17.2 Users shall hold the Platform harmless for any claims or disputes.
      </p>

      <p className='font-light mb-4'>
        17.3. You agree to indemnify us against any claims, damages, or expenses
        arising from your use of the Platform.
      </p>

      <h4 className='font-medium py-4'>18. Termination</h4>
      <p className='font-light mb-4'>
        18.1 We may terminate your Account or access to the Platform at any time
        without notice if you violent any of this terms and conditions.
      </p>

      <p className='font-light mb-4'>
        18.2. You may terminate your Account at any time by written notice to
        us.
      </p>

      <p className='font-light mb-4'>
        18.3. Users must immediately cease using the Platform upon termination.
      </p>

      <h4 className='font-medium py-4'>19. Governing Law</h4>
      <p className='font-light mb-4'>
        19.1. These Terms shall be governed by and construed in accordance with
        [Your State/Country] law.
      </p>

      <p className='font-light mb-4'>
        19.2. Any disputes shall be resolved through binding arbitration in
        accordance with [Your State/Country] law.
      </p>

      <h4 className='font-medium py-4'>20. Entire Agreement</h4>
      <p className='font-light mb-4'>
        20.1. These Terms constitute the entire agreement between you and us
        regarding the use of the Platform.
      </p>

      <p className='font-light mb-4'>
        20.2. These Terms supersede all prior or contemporaneous agreements or
        understandings.
      </p>

      <h4 className='font-medium py-4'>21. Amendments</h4>
      <p className='font-light mb-4'>
        21.1. We may modify or update these Terms at any time without notice.
      </p>

      <p className='font-light mb-4'>
        21.2. The Platform reserves the right to amend these Terms at any time.
      </p>

      <p className='font-light mb-4'>
        21.3. Users shall be notified of any amendments through the Platform.
      </p>
    </div>
  );
};
