import { Tab } from '@headlessui/react';
import { PlayIcon } from '@heroicons/react/24/solid';

import { CourseDescription } from '../components/CourseDescription';
import { CourseReviews } from '../components/CourseReviews';
import { StudentFeedback } from '../components/StudentFeedback';

type ContentItemProps = {
  isCompleted: boolean;
  title: string;
  duration: string;
  index: number;
};

const courseDetails = [
  {
    title: 'Javascript Basics',
    isCompleted: true,
    duration: '3 mins',
  },
  {
    title: 'Javascript Basics',
    isCompleted: true,
    duration: '3 mins',
  },
  {
    title: 'Javascript Basics',
    isCompleted: false,
    duration: '3 mins',
  },
  {
    title: 'Javascript Basics',
    isCompleted: false,
    duration: '3 mins',
  },
  {
    title: 'Javascript Basics',
    isCompleted: false,
    duration: '3 mins',
  },
  {
    title: 'Javascript Basics',
    isCompleted: false,
    duration: '3 mins',
  },
];

const ContentItem = ({
  isCompleted,
  title,
  duration,
  index,
}: ContentItemProps) => {
  return (
    <div
      className={`flex justify-between items-center h-[32px] w-full md:w-[60vw] mb-2 p-2 ${
        isCompleted ? 'bg-[#C3FFE0]' : 'bg-[#EBEBEB]'
      }`}
    >
      <div className='flex items-center'>
        <input
          type='checkbox'
          className='form-checkbox h-3 w-3 text-indigo-600 mr-4 text-indigo bg-transparent rounded-sm'
          checked={isCompleted}
          disabled={true}
          //onChange={handleCheckboxChange}
        />
        <span className='mr-4 text-sm font-medium'>{index}</span>

        <span className='text-sm font-medium'>{title}</span>
      </div>

      <div className='flex items-center'>
        <PlayIcon className='h-3 w-3 mr-4 bg-indigo text-white rounded-full p-[2px]' />
        <span className='text-sm font-medium'>{duration}</span>
      </div>
    </div>
  );
};

export const PurchasedCourseDetails = () => {
  return (
    <div className='px-8 py-2 mt-8 sm:mt-0 mb-8 sm:my-0'>
      <h3 className='text-lg font-medium text-indigo mb-2'>
        JavaScript Beginers Guide
      </h3>

      <div
        className='flex flex-[0.4] min-h-[30vh] sm:min-h-[0vh] flex-col justify-center bg-cover bg-center items-center'
        style={{
          backgroundImage:
            'url(https://image.tmdb.org/t/p/original/iuFNMS8U5cb6xfzi51Dbkovj7vM.jpg)',
        }}
      >
        <div className='w-full h-full min-h-[60vh] backdrop-brightness-50'>
          <div className='h-full w-full min-h-[60vh] flex flex-col justify-center items-center'>
            <a href='/#'>
              <div className='text-white hover:text-primary flex justify-center items-center h-12 w-12 transparent-bg p-2 rounded-full hover:bg-slate-200 '>
                <PlayIcon className='h-6 w-6 ' />
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className='my-4'>
        <Tab.Group>
          <Tab.List className='flex space-x-1 p-1 border-slate-300 border-b-[0px]'>
            <Tab
              key='course content header'
              className={({ selected }) => {
                return `${
                  selected
                    ? 'border-b-[4px] border-yellow text-indigo'
                    : 'text-slate-400'
                } mr-4`;
              }}
            >
              Course Content
            </Tab>

            <Tab
              key='course overview header'
              className={({ selected }) => {
                return selected
                  ? 'border-b-[4px] border-yellow text-indigo'
                  : 'text-slate-400';
              }}
            >
              Course Overview
            </Tab>
          </Tab.List>
          <Tab.Panels className='mt-2'>
            <Tab.Panel key={'course content'} className={``}>
              <div>
                {courseDetails.map((item, index) => (
                  <ContentItem
                    key={index}
                    index={index}
                    title={item.title}
                    duration={item.duration}
                    isCompleted={item.isCompleted}
                  />
                ))}
              </div>
            </Tab.Panel>

            <Tab.Panel key={'course overview'} className={``}>
              <div>
                <CourseDescription />

                <div className='mt-6'>
                  <StudentFeedback />
                </div>

                <CourseReviews />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};
