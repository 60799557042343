import omit from 'lodash/omit';
import { RestRequest, createResponseComposition, context } from 'msw';

import { JWT_SECRET } from '@/config';
import { sign, verify } from '@/utils/jwt';

import { db } from './db';

const isTesting =
  process.env.NODE_ENV === 'test' || ((window as any).Cypress as any);

export const delayedResponse = createResponseComposition(undefined, [
  context.delay(isTesting ? 0 : 1000),
]);

export const hash = (str: string) => {
  let hash = 5381,
    i = str.length;

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }
  return String(hash >>> 0);
};

export const sanitizeUser = (user: any) => omit(user, ['password']);

export const authenticate = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const user = db.user.findFirst({
    where: {
      email: {
        equals: email,
      },
    },
  });

  if (user && user.password === hash(password)) {
    const sanitizedUser = sanitizeUser(user);
    const encodedToken = await sign(sanitizedUser, JWT_SECRET);

    return {
      msg: 'SUCCESS',
      resource: sanitizedUser,
      extra: {
        accessToken: encodedToken,
      },
    };
  }

  const error = new Error('Invalid username or password');
  throw error;
};

export const requireAuth = async (request: RestRequest) => {
  try {
    const encodedToken = request.headers.get('x-auth-token');
    if (!encodedToken) {
      throw new Error('No authorization token provided!');
    }
    const decodedToken = (await verify(encodedToken, JWT_SECRET)) as {
      _id: string;
    };

    const user = db.user.findFirst({
      where: {
        _id: {
          equals: decodedToken._id,
        },
      },
    });

    if (!user) {
      throw Error('Unauthorized');
    }

    return sanitizeUser(user);
  } catch (err: any) {
    throw new Error(err);
  }
};

export function requireAdmin(user: any) {
  if (!user.role.includes('admin')) {
    throw Error('Unauthorized');
  }
}
