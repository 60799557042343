import { support } from '@/assets';

export const Support = () => {
  return (
    //flex  my-8
    <div className='flex flex-col sm:flex-row px-8 mt-8 sm:mt-0 mb-8 sm:my-0 sm:p-8'>
      <div className='flex flex-1 justify-center mb-6 sm:mb-0'>
        <img className='h-[250px]' src={support} alt='support' />
      </div>

      <div className='flex-1'>
        <h3 className='font-medium text-lg mb-4'>SUPPORT</h3>

        <p>
          Your success is our priority, and it knows no time bounds. Once you
          become a part of Awap Investors, you gain access to round-the-clock
          support and attention. We&lsquo;re here whenever you need us, ensuring
          you&lsquo;re never alone on your financial journey.
        </p>

        {/* <button className='bg-indigo  mt-8 hover:bg-yellow text-[12px] h-[40px] w-[200px] p-2 rounded-md text-white text-transform: uppercase'>
          Subscribe
        </button> */}
      </div>
    </div>
  );
};
