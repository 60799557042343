export const CourseDescription = () => {
  return (
    <div className=''>
      <h3 className='text-lg font-medium text-indigo mb-6'>
        Course Description
      </h3>

      <div className='flex'>
        <div className='flex sm:flex-[0.8]'>
          <p className='text-sm leading-6'>
            Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt
            qui esse pariatur duis deserunt mollit dolore cillum minim tempor
            enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut
            voluptate aute id deserunt nisi.
            <br /> Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
            Sunt qui esse pariatur duis deserunt mollit dolore cillum minim
            tempor enim. Elit aute irure tempor cupidatat incididunt sint
            deserunt ut voluptate aute id deserunt nisi. \Aliqua id fugiat
            nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur
            duis deserunt mollit dolore cillum minim tempor enim. Elit aute
            irure tempor cupidatat incididunt sint deserunt ut voluptate aute id
            deserunt nisi.{' '}
          </p>
        </div>
      </div>
    </div>
  );
};
