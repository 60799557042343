/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import {
  beginnerClass,
  intermediateClass,
  advancedClass,
  threeInOne,
} from '@/assets';
import { Modal } from '@/components/Modal';

import { ClassCategory, EnrolmentForm } from './EnrolmentForm';

const classCategories = [
  {
    id: 'Beginner',
    title: 'Beginner Class',
    imgSrc: beginnerClass,
  },
  {
    id: 'Intermediate',
    title: 'Intermediate Class',
    imgSrc: intermediateClass,
  },
  {
    id: 'Advanced',
    title: 'Advanced Class',
    imgSrc: advancedClass,
  },
  {
    id: '3IN1',
    title: '3 In 1',
    imgSrc: threeInOne,
  },
];

export const ClassCategories: React.FC = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<ClassCategory | ''>(
    '',
  );

  const openModal = () => {
    setIsFormOpen(true);
  };

  const closeModal = () => {
    setIsFormOpen(false);
  };

  const handleEnrolBtn = (category: ClassCategory) => {
    setSelectedCategory(category);
    openModal();
  };

  return (
    <>
      <div className='flex flex-col sm:flex-row w-full px-8  mt-4 flex-wrap max-w-xl mx-auto'>
        {classCategories.map((item, index) => (
          <div key={index} className='mb-8 mr-0 sm:mr-4 flex flex-col w-full'>
            <img src={item.imgSrc} alt={item.title} className='w-[500px]' />

            <button
              onClick={() => handleEnrolBtn(item.id as ClassCategory)}
              type='button'
              className='self-center bg-indigo  mt-8 hover:bg-yellow text-[14px] h-[40px] min-w-[200px] p-2 rounded-md text-white'
            >
              Enrol for {item.title}
            </button>
          </div>
        ))}
      </div>
      <Modal isOpen={isFormOpen} closeModal={closeModal}>
        <EnrolmentForm
          classCategory={selectedCategory}
          closeForm={closeModal}
        />
      </Modal>
    </>
  );
};
