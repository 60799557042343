import React from 'react';

export const PrivacyPolicy = () => {
  return (
    <div className='max-w-4xl mx-auto min-h-[60vh] py-4 text-indigo px-8'>
      <h3 className='text-center font-semibold text-2xl py-4'>
        Privacy Policy
      </h3>

      <h4 className='font-medium text-center py-4'>
        Effective Date: 1st May, 2024
      </h4>

      <p className='font-light mb-4'>
        At AWAP INVESTORS LTD, we are committed to protecting the privacy and
        security of our users&lsquo; personal information. This privacy policy
        explains how we collect, use, and disclose personal information from
        users of our online global investment platform, where people can invest,
        get loans, make savings, buy digital shares, engage in online business
        mentorship classes, and buy and sell crypto.
      </p>

      <h4 className='font-medium text-center py-4'>Information We Collect</h4>
      <ul>
        <li className='font-light list-disc list-inside mb-2'>
          Contact information (name, email address, phone number, physical
          address) - Identification information (passport, driver&lsquo;s
          license, national ID, social security number)
        </li>

        <li className='font-light list-disc list-inside mb-2'>
          Identification information (passport, driver&lsquo;s license, national
          ID, social security number)
        </li>

        <li className='font-light list-disc list-inside mb-2'>
          Financial information (bank account details, credit card information,
          income, employment status)
        </li>

        <li className='font-light list-disc list-inside mb-2'>
          Transaction information (investment history, loan applications,
          savings activity, payment history)
        </li>

        <li className='font-light list-disc list-inside mb-2'>
          Online activity information (cookies, browsing history, search
          queries, IP address)
        </li>

        <li className='font-light list-disc list-inside mb-2'>
          Communication information (email, phone calls, chat messages, support
          tickets)
        </li>

        <li className='font-light list-disc list-inside mb-4'>
          Device information (device type, operating system, browser type,
          device ID)
        </li>
      </ul>

      <h4 className='font-medium text-center py-4'>
        How We Use Your Information
      </h4>

      <ul>
        <li className='font-light list-disc list-inside mb-2'>
          Provide and improve our services
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Verify your identity and prevent fraud
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Communicate with you about your account and transactions
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Comply with legal and regulatory requirements
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Send marketing and promotional materials (with your consent)
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Analyze and improve our platform&lsquo;s performance and user
          experience
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Detect and prevent fraud and security breaches
        </li>
        <li className='font-light list-disc list-inside mb-4'>
          Enforce our terms and conditions and legal agreements
        </li>
      </ul>

      <h4 className='font-medium text-center py-4'>
        How We Share Your Information
      </h4>

      <ul>
        <li className='font-light list-disc list-inside mb-2'>
          Third-party service providers (payment processors, custodians,
          auditors, etc.)
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Regulatory bodies and law enforcement agencies (as required by law)
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Our affiliates and subsidiaries (for business purposes)
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Business partners and collaborators (for joint marketing and
          promotional activities)
        </li>
        <li className='font-light list-disc list-inside mb-4'>
          Successors and assigns (in the event of a merger, acquisition, or
          bankruptcy)
        </li>
      </ul>

      <h4 className='font-medium text-center py-4'>Data Security</h4>

      <ul>
        <li className='font-light list-disc list-inside mb-2'>
          Implement robust security measures to protect your personal
          information from unauthorized access, disclosure, or use
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Use encryption, firewalls, and secure servers to safeguard your data
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Regularly update and patch our systems to prevent vulnerabilities
        </li>
        <li className='font-light list-disc list-inside mb-4'>
          Limit access to your personal information to authorized personnel only
        </li>
      </ul>

      <h4 className='font-medium text-center py-4'>Data Retention</h4>

      <ul>
        <li className='font-light list-disc list-inside mb-2'>
          Retain your personal information for as long as necessary to provide
          our services and comply with legal requirements
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Delete your personal information upon request or when it is no longer
          necessary
        </li>
        <li className='font-light list-disc list-inside mb-4'>
          Retain transaction records and financial information for at least 5
          years to comply with legal and regulatory requirements
        </li>
      </ul>

      <h4 className='font-medium text-center py-4'>Your Rights</h4>

      <ul>
        <li className='font-light list-disc list-inside mb-2'>
          Access and correct your personal information
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Request deletion of your personal information
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Object to processing of your personal information
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          Request a copy of your personal information (in a portable format)
        </li>
        <li className='font-light list-disc list-inside mb-4'>
          Complain to a supervisory authority if you believe your rights have
          been violated
        </li>
      </ul>

      <h4 className='font-medium text-center py-4'>
        Changes to This Privacy Policy
      </h4>

      <ul>
        <li className='font-light list-disc list-inside mb-2'>
          We may update this privacy policy from time to time
        </li>
        <li className='font-light list-disc list-inside mb-2'>
          We will notify you of significant changes by email or through our
          platform
        </li>
        <li className='font-light list-disc list-inside mb-4'>
          We will obtain your consent before making material changes to this
          privacy policy
        </li>
      </ul>

      <h4 className='font-medium text-center py-4'>Contact Us</h4>

      <ul>
        <li className='font-light list-disc list-inside mb-2'>
          If you have questions or concerns about this privacy policy, please
          contact us at info@awapinvestors.com
        </li>
        <li className='font-light list-disc list-inside mb-4'>
          If you wish to exercise your rights or make a complaint, please
          contact our Data Protection Officer at info@awapinvestors.com
        </li>
      </ul>
    </div>
  );
};
