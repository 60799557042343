import { axios } from '@/lib/axios';
import storage from '@/utils/storage';

import { ForgotPasswordInput } from '../components/ForgotPasswordForm';
import { ResetPasswordInput } from '../components/ResetPasswordForm';
import { SignInInput } from '../components/SignInForm';
import { SignUpInput } from '../components/SignUpForm';
import { UpdatePasswordInput } from '../components/UpdatePasswordForm';
import { VerifyAccountInput } from '../components/VerifyAccountForm';

export const login = async (credentials: SignInInput) => {
  const response = await axios.post('/users/login', credentials);
  return response.data;
};

export const logout = async () => {
  storage.clearToken();
  storage.clearUser();
};

export const verifyAccount = async (credentials: VerifyAccountInput) => {
  const response = await axios.patch('/users/verify', credentials);
  return response.data;
};

export const register = async (credentials: SignUpInput) => {
  const response = await axios.post(
    `/users${
      credentials.referredBy
        ? `?referralCode=${credentials.referredBy.trim()}`
        : ''
    }`,
    credentials,
  );
  return response.data;
};

export const forgotPassword = async (credentials: ForgotPasswordInput) => {
  const response = await axios.post('/users/forget-password', credentials);
  return response.data;
};

export const resetPassword = async (credentials: ResetPasswordInput) => {
  const response = await axios.patch('/users/reset-password', credentials);
  return response.data;
};

export const updatePassword = async (credentials: UpdatePasswordInput) => {
  const response = await axios.patch('/users/update-password', credentials);
  return response.data;
};

export const activateAccount = async () => {
  const response = await axios.patch('/users/activate-account');
  return response.data;
};
