const Card = ({ title, content }: { title: string; content: string }) => {
  return (
    <div className='p-4 border-[1px] rounded-md mr:0 sm:mr-2 mb-4 sm:mb-0'>
      <h3 className='font-medium text-md mb-4'>{title}</h3>
      <p className='text-[12px]'>{content}</p>
    </div>
  );
};

export const Offer = () => {
  return (
    <div className='px-8 mt-8 sm:mt-0 mb-8 sm:my-0 sm:p-8'>
      <h2
        id='services'
        className='text-lg font-semibold text-center mb-8 text-transform: uppercase'
      >
        Our Services
      </h2>

      <div className='flex flex-col sm:flex-row'>
        <Card
          title='Financial Services'
          content="We provide transparent financial services such as savings, loans, and investments to support members' business growth and address digital financial challenges."
        />

        <Card
          title='Awap Digital Academy'
          content='Our Digital Skills Academy educates and empowers members with various digital skills, such as programming, digital marketing, AI, and more, using convenient technology-driven sessions and a comprehensive syllabus for quick learning.'
        />

        <Card
          title='Business Coach & Mentorship'
          content="We provide business support services to bridge the knowledge gap and facilitate the healthy growth of members' businesses. We offer 24/7 support, help with operations, and assist in accessing loans, grants, and government support."
        />

        <Card
          title='Buying & Selling of Crypto'
          content="We trade cryptos precisely stable coins to bridge the gap between the digital assets and our investors, the crypto trading platform provide quick access to funds and minimize risk while working or investing with our company. It's reliable, fast and trustworthy. Let's deal."
        />
      </div>
    </div>
  );
};
